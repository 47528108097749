
import Vue from 'vue'
import Router from 'vue-router'
import rts from '@/routes'
import i18n from './i18n'

Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    beforeEnter: (to, from, next) => {
      let language = to.params.lang
      if (!language) {
        language = 'en'
      }

      // set the current language for i18n.
      i18n.locale = language
      next()
    },
    children: rts
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

export default router
