import { DateTime } from 'luxon'

export const SET_ALL_ROOMS = (state, rooms) => {
  state.rooms = rooms
}

export const SET_AVAILABLE_ROOMS = (state, rooms) => {
  state.availableRooms = rooms
}

export const SET_ROOM_DETAILS = (state, room) => {
  state.roomDetails = room
  // console.log('STATE ROOM ID: ' + state.roomDetails.id)
}

export const SET_ROOM_RESERVATIONS = (state, roomReservations) => {
  state.roomReservations = roomReservations
}

export const SET_ORIGINAL_DATES = (state, dates) => {
  // format from VueHotelDatepicker: YYYY/MM/DD, type is string
  state.checkin = dates.start
  state.checkout = dates.end
}

export const SET_OCTOBER_DATES = (state, dates) => {
  // startDate object: Date Mon Sep 07 2020 00:00:00 GMT+0200 (GMT+02:00)
  const startDate = new Date(state.checkin)
  // start (luxon DateTime object): Object { ts: 1598997600000, _zone: {}, loc: {…}, invalid: null, weekData: null, c: {…}, o: 120, isLuxonDateTime: true }
  const start = DateTime.fromISO(startDate.toISOString())
  // console.log(start)
  const endDate = new Date(state.checkout)
  const end = DateTime.fromISO(endDate.toISOString())
  // console.log(state.checkout) // sting like 2020/02/21
  // console.log(endDate) // Date ISO: Date Thu Jul 22 2021 00:00:00 GMT+0200 (Mitteleuropäische Sommerzeit)
  // console.log(start) // DateTime Object
  // console.log(end) // DateTime Object

  state.ocDates.checkin = start.toFormat('yyyy-MM-dd HH:mm:ss')
  state.ocDates.checkout = end.toFormat('yyyy-MM-dd HH:mm:ss')
  // the first 10 char of october date format: yyyy-MM-dd
  state.startDate = start.toFormat('yyyy-MM-dd')
  // console.log('Mutation startDate' + state.startDate)
  state.endDate = end.toFormat('yyyy-MM-dd')
  // console.log(typeof state.endDate)  type: string
  state.prStart = start.toFormat('dd.MM.yyyy')
  state.prEnd = end.toFormat('dd.MM.yyyy')
  // console.log(state.prStart)
}

export const SET_CONFIRMATION_DATA = (state) => {
  const startDate = new Date(state.checkin)
  const start = DateTime.fromISO(startDate.toISOString())
  const endDate = new Date(state.checkout)
  const end = DateTime.fromISO(endDate.toISOString())

  state.toConfirm = false
  state.confirmationData.checkin = start.toFormat('dd.MM.yyyy')
  state.confirmationData.checkout = end.toFormat('dd.MM.yyyy')
  state.confirmationData.daysBetween = end.diff(start, ['days'])
  // console.log(state.confirmationData.daysBetween.values.days  + '.... Days Between ...')

  state.confirmationData.aDayPrice = state.roomDetails.price
  state.confirmationData.totalPrice = (state.confirmationData.aDayPrice * state.confirmationData.daysBetween.values.days).toFixed(2)
  state.toConfirm = true
}

export const SET_ROOM_PAID = (state, paidStatus) => {
  state.roomPaidStatus = paidStatus
}

export const SET_APPROVED_URL = (state, approvedUrl) => {
  state.approvedUrl = approvedUrl
}

export const SET_LANDLORD_ROOMS = (state, rooms) => {
  state.landlordRooms = rooms
}

export const SET_ROOM_TO_EDIT = (state, room) => {
  state.roomToEdit = room
}

export const SET_BILL_NUMBER = (state, number) => {
  state.billNumber = number
}

export const SET_CORE_DATA = (state, coreData) => {
  state.coreData = coreData
}
