<template>
  <div id="room-management">
    <div class="row">
      <div class="col s12 m3">
        <div class="col s12 m12">
          <div class="room-menu">
            <h4 class="center grey-text text-darken-2 font-title-size">{{ $t('room.room_menu') }}</h4>
            <div class="card-panel" :class="{ active: isAdd, inactive: !isAdd}">
              <a @click="isAdd = true; isManage = false" class="transparent z-depth-0">
                {{ $t('room.add_room') }}
              </a>
            </div>
            <div class="card-panel"  :class="{ active: isManage, inactive: !isManage}">
              <a @click="isAdd = false; isManage = true"
                 class="transparent z-depth-0 tooltipped"
                 data-position="bottom" :data-tooltip="$t('room.manage_items')"
              >
                {{ $t('room.manege_rooms') }}
              </a>
            </div>
            <div class="card-panel grey lighten-3">
              <a class="transparent z-depth-0 orange-text waves-effect waves-black tooltipped"
                 data-position="bottom" :data-tooltip="$t('room.for_later')">{{ $t('room.further') }}</a>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isAdd">
        <div class="col s12 m9">
          <add-room @create-room="createRoom"></add-room>
        </div>
      </div>
        <!-- edit and delete management -->
      <div v-if="isManage">
        <div class="col s12 m9">
          <manage-rooms></manage-rooms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AddRoom from './AddRoom'
import ManageRooms from './ManageRooms'
export default {
  name: 'RoomManagement',

  data () {
    return {
      isAdd: true,
      isManage: false,
      isFurther: false
    }
  },
  components: {
    ManageRooms,
    // ObjectGuide,
    AddRoom
  },

  methods: {
    async createRoom (fd) {
      await this.$store.dispatch('rooms/addRoom', fd).then((response) => {
        // console.log(response.status)
        // console.log(response.data)
        if(response.status === 200){
          document.location.reload()
        }
      })
    }
  },

  computed: {
    token () {
      return this.$store.getters['auth/token']
    }
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
    // console.log(this.$store.getters['auth/user'])
  }
}
</script>

<style lang="scss" scoped>
/* Small */

/* Middle */
@media screen and (max-width: 759px) {

}
/* Wide */
@media screen and (min-width: 760px) {

}
.room-menu .card-panel a:hover{
  cursor: pointer;
}
.active {
  background: #ffb74d;
}
.active a{
  color: #fff;
}
.inactive {
  background: #eeeeee;
}
.inactive a{
  color: orange;
}
</style>
