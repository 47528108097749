export default {
  user: {},
  profile: null,
  user_role: 'renter',
  authenticated: false,
  token: '',
  countries: [],
  register_user_role: '',
  hirer: {},
  hirer_profile: null,
  customerNumber: null
}
