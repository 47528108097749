<template>
  <div id="modal-profile" class="modal">
    <div class="modal-content">
      <div class="row" style="margin-left: 0; margin-right: 0;">
        <h4 class="grey-text text-darken-2">{{ username }}</h4>
        <form id="profile-form" role="form" @submit.prevent="updateUser">
          <div class="row" style="margin-bottom: 5px; margin-top: 5px;">
            <div class="col s12 l3" style="margin-left:0; margin-right: 0">
              <label>{{ $t('salutation') }}</label>
              <select class = "browser-default" v-model="profile.gender">
                <option value="male" selected>{{ $t('male') }}</option>
                <option value="female">{{ $t('female') }}</option>
              </select>
            </div>
            <div class="input-field col s12 l4" style="margin-left:0; margin-right: 0">
              <input type="text" id="name" class="validate" v-model="name" required>
              <label for="name">{{ $t('nav.name') }}</label>
            </div>
            <div class="input-field col s12 l5">
              <input type="text" id="surname" class="validate" v-model="surname" required>
              <label for="surname">{{ $t('nav.surname') }}</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12">
              <input type="email" id="email" class="validate" v-model="email" required>
              <label for="email">Email</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 l6">
              <input type="password" id="password" class="validate" minlength="7"
                     v-model="password" required>
              <label for="password">{{ $t('nav.password') }}</label>
            </div>
            <div class="input-field col s12 m6">
              <input type="password" id="password_confirmation" class="validate" minlength="7"
                     v-model="password_confirmation" required>
              <label for="password_confirmation">{{ $t('nav.confirm_password') }}</label>
            </div>
          </div>
          <div class="input-field col s12 m12">
            <input type="text" id="organization" v-model="organization">
            <label for="organization">{{ $t('nav.organization') }}</label>
          </div>
          <!-- /div -->
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="phone" class="validate" v-model="phone" required>
              <label for="phone">{{ $t('nav.phone') }}</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="mobile" class="validate" v-model="mobile">
              <label for="mobile">{{ $t('nav.mobile') }}</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="fax" class="validate" v-model="fax">
              <label for="fax">{{ $t('nav.fax') }}</label>
            </div>
          </div>
          <div class="row tooltipped" data-position="bottom" :data-tooltip="$t('nav.physical_address')"
               style="margin-bottom: 5px;">
            <div class="input-field col s12 m6">
              <input type="text" id="country" class="validate" v-model="country" required>
              <label for="country">{{ $t('nav.country') }}</label>
            </div>
            <div class="input-field col s4 m2">
              <input type="text" id="postal-code" class="validate" v-model="postal" required>
              <label for="postal-code">{{ $t('nav.postal') }}</label>
            </div>
            <div class="input-field col s8 m4">
              <input type="text" id="location" class="validate" v-model="location" required>
              <label for="location">{{ $t('nav.location') }}</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s8">
              <input type="text" id="street" class="validate" v-model="street" required>
              <label for="street">{{ $t('nav.street') }}</label>
            </div>
            <div class="input-field col s4">
              <input type="text" id="house-number" class="validate" v-model="house_number" required>
              <label for="house-number">{{ $t('nav.number') }}</label>
            </div>
          </div>
          <!-- to watch change of profile data -->
          <!--div hidden>
            <ul>
              <li v-for="(elm, index) in profile" :key="index">{{ elm }}</li>
            </ul>
          </div-->
          <button type="submit" class="btn grey darken-2 z-depth-0">
            {{ $t('save') }}
          </button>
          <span class="transparent-text">&nbsp;&nbsp;</span>
          <a id="close-profile" class="modal-action modal-close btn grey z-depth-0">
            <i class="fa fa-lock"></i> {{ $t('cancel') }}
          </a>
          <span class="transparent-text">&nbsp;&nbsp;</span>
          <a id="delete-profile" class="modal-action modal-close btn pink accent-2 z-depth-0" @click="deleteUserProfile">
            <i class="material-icons">delete</i>
            {{ $t('delete') }}
          </a>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint no-undef: 0 */
import { isEmpty } from 'lodash'
import homeMixin from '@/mixins/homeMixin'

export default {
  name: 'Profile',
  data () {
    return {
      name: null,
      surname: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,
      gender: null,
      organization: null,
      role: null,
      phone: null,
      mobile: null,
      fax: null,
      country: null,
      postal: null,
      location: null,
      street: null,
      house_number: null,

      user_id: null,
      isProfileChanged: false,
      isDisabled: process.env.VUE_APP_UPDATE_USER
    }
  },

  components: {
    // CountrySelect
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    profile () {
      return this.$store.getters['auth/profile']
    }
  },

  created () {
    // console.log('Profile: Profile Created: ' + this.isProfileChanged)
  },

  beforeMount () {
    // Registration data which could be changed
    // console.log('Profile: Before Mount')
  },

  mounted () {
    // console.log('Mount in Profile')
    this.$nextTick(() => {
      let $ = window.jQuery
      $('.modal').modal({
        inDuration: 1000,
        outDuration: 500
      })
      $('.tooltipped').tooltip()
    })
    this.name = this.user.name
    this.surname = this.user.surname
    this.username = this.user.username
    this.email = this.user.email
    this.password = this.user.password
    // console.log(this.password)

    if (this.profile !== null) {
      this.gender = this.profile.gender
      this.organization = this.profile.organization
      this.role = this.profile.role
      this.phone = this.profile.phone
      this.mobile = this.profile.mobile
      this.fax = this.profile.fax
      this.country = this.profile.country
      this.postal = this.profile.postal
      this.location = this.profile.location
      this.street = this.profile.street
      this.house_number = this.profile.house_number
    }
  },

  beforeUpdate () {
    // console.log('Profile: beforeUpdate')
  },

  updated () {
    // console.log('USER NAME')
    // console.log(this.user.name)
    // console.log('Profile: Register data are changed: ' + this.isProfileChanged)
  },

  methods: {
    setSelectedCountry (country) {
      this.country = country
    },
    async updateUser () {
      const modal = document.querySelector('#modal-profile')
      if (this.isProfileChanged) {
        const payload = {
          'id': this.user.id,
          'name': this.name,
          'surname': this.surname,
          'username': this.username,
          'email': this.email,
          'password': this.password,
          'password_confirmation': this.password_confirmation,
          'gender': this.gender,
          'organization': this.organization,
          'role': this.role,
          'phone': this.phone,
          'mobile': this.mobile,
          'fax': this.fax,
          'country': this.country,
          'postal': this.postal,
          'location': this.location,
          'street': this.street,
          'house_number': this.house_number
        }
        // to update user table and profile table in DB
        await this.$store.dispatch('auth/updateUser', payload).then(() => {
          this.$store.dispatch('auth/logout')
          const logUser = new URLSearchParams()
          logUser.append('login', this.email)
          logUser.append('password', this.password)
          this.$store.dispatch('auth/loginUser', logUser).then((response) => {
            // console.log(response.data.user)
            this.user_id = response.data.user.id
            this.error = ''
          })

          M.toast({ html: this.$i18n.t('auth.profile_updated'), classes: 'rounded orange-text' })
          M.Modal.getInstance(modal).close()
        })
      }
    },

    async deleteUser () {
      await this.$store.dispatch('auth/deleteUser', this.user).then((response) => {
        // console.log('User and Profile are deleted')
        // console.log(response.data)
        this.$store.dispatch('auth/logout')
        M.toast({ html: this.$i18n.t('auth.user_deleted'), classes: 'rounded orange-text' })
      }).catch((error) => {
        // console.log('User to delete ERROR')
        console.log(error.response.data.error)
      })
    },
    async deleteUserProfile () {
      MaterialDialog.dialog(
        this.$i18n.t('auth.sure_delete_account'),
        {
          title: this.$i18n.t('warning'),
          modalType: '',
          buttons: {
            close: {
              className: 'grey',
              text: this.$i18n.t('cancel'),
              callback: () => {}
            },
            confirm: {
              className: 'pink',
              text: this.$i18n.t('yes'),
              modalClose: true,
              callback: () => {
                this.$store.dispatch('auth/deleteProfile', this.profile).then(() => {
                  this.deleteUser()
                })
              }
            }
          }
        }
      )
    }
  },

  watch: {
    name (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
      // console.log('new: %s, old: %s', a, b)
    },
    surname (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    username (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    email (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    password (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    password_confirmation (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    gender (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    organization (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    phone (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    mobile (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    fax (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    country (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    postal (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    location (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    street (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    house_number (a, b) {
      if (!isEmpty(b)) {
        this.isProfileChanged = true
      }
    },
    user_id () {
      // store get new user profile data
      this.$store.dispatch('auth/getUserProfile', this.user_id)
    }
  },
  beforeDestroy () {
    // console.log('Profile: Before Destroyed')
  },
  destroyed () {
    // console.log('Profile: Destroyed')
  },

  mixins: [homeMixin]
}
</script>

<style scoped>

</style>
