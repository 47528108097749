<template>
  <a href="javascript:void(0);"
     class="waves-effect waves-block waves-light sidenav-trigger show-on-large tooltipped"
     data-position="left" :data-tooltip=to_menu
     data-target="mobile-links">
    <i class="material-icons">menu</i>
  </a>
</template>

<script>
export default {
  name: 'MenuIcon',
  data () {
    return {
      to_menu: this.$i18n.t('nav.menu_icon')
    }
  },
  mounted () {
    const $ = window.jQuery
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  }
}
</script>

<style scoped>

</style>
