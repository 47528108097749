import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_BASE_URL

// console.log(apiUrl)

export const getAllRooms = ({ commit, state }) => {
  axios.get(apiUrl + '/rooms').then((response) => {
    // console.log(response.data)
    commit('SET_ALL_ROOMS', response.data)
  })
}

export const getAvailableRooms = ({ commit, state }, dates) => {
  // console.log(dates.checkin + ' .... ACTION')

  // console.log(dates)

  const availableRooms = []

  state.rooms.forEach(room => {
    if (room.reservation.length > 0) {
      const overlaps = []

      room.reservation.forEach(date => {
        // reserved (booked) date
        const reservedFrom = new Date(date.startdate)
        // console.log('Reserved From' + reservedFrom)
        const reservedTo = new Date(date.enddate)

        // desired date period to reserve stored in state
        const checkinDate = new Date(dates.checkin)
        // console.log('CheckIn-Date: ' + checkinDate) // e.g. Wed Oct 21 2020 02:00:00 GMT+0200
        const checkoutDate = new Date(dates.checkout)

        // push true (reserved) or false (free)
        overlaps.push((reservedFrom < checkoutDate) && (checkinDate < reservedTo))
      })
      if (!overlaps.includes(true)) {
        availableRooms.push(room)
      }
      return
    }
    // available room if it has no dates entry
    availableRooms.push(room)
  })
  commit('SET_AVAILABLE_ROOMS', availableRooms)
}

export const setRoomDetails = ({ commit, state }, id) => {
  const room = state.rooms.find((room) => room.id === parseInt(id))
  // console.log('Action setRoomDetails of ID: ' + room.id)
  commit('SET_ROOM_DETAILS', room)
}

export var setDates = ({ commit, state }, payload) => {
  // console.log(payload)

  if (payload.type === 'pickup') {
    commit('SET_PICKUP', payload.value)
    localStorage.setItem('pickup', payload.value)
    return
  }
  commit('SET_DROPOFF', payload.value)
  localStorage.setItem('dropoff', payload.value)
}

// to use in post payment method
export const setToken = ({ state }, token) => {
  state.token = token
}

export const createPayment = ({ commit, state }, payload) => {
  commit('SET_ROOM_PAID', '')

  // console.log('Token: ' + state.token)

  const headers = {
    'Authorization': 'Bearer ' + state.token
  }
  // console.log('TOKEN: ' + token)
  return axios.post(apiUrl + '/create-payment', payload, { headers: headers })
    .then((response) => {
      // console.log(response.data)
      // commit('SET_APPROVED_URL', response.data)
      let status = 'success'
      commit('SET_ROOM_PAID', status)
    })
    .catch(errors => {
      commit('SET_ROOM_PAID', status)
    })
}

export const setConfirmDates = ({ commit, state }, dates) => {
  // console.log(dates)
  commit('SET_ORIGINAL_DATES', dates)
  commit('SET_OCTOBER_DATES')
  commit('SET_CONFIRMATION_DATA')
}

export const storeReservation = ({ commit }, reservationData) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/store-reservation', reservationData)
      .then((response) => {
        commit('SET_BILL_NUMBER', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
        // console.log('Something went wrong on storing reservation!')
        // console.log(error)
      })
  })
  // return axios.post(apiUrl + '/store-reservation', reservationData).then((response) => {
  //   commit('SET_BILL_NUMBER', response.data)
  //   // console.log('Rechnungsnummer: ' + response.data)
  // }).catch((error) => {
  //   console.log('Something went wrong!: ' + error.response)
  // })
}

/* Room Management */
/* ===================================================================== */
// export const addRoom = ({ commit, state }, data) => {
// let fd = data.fd
// console.log(data.token)
// const headers = {
//   'Authorization': 'Bearer ' + data.token,
//   'Content-Type': 'multipart/form-data'

export const addRoom = ({ commit, state }, fd) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/add-room', fd)
      .then((response) => {
        resolve(response)
        // console.log(response.data)
        // console.log(response.status)
      })
      .catch(error => {
        reject(error)
        // console.log('Something went wrong !')
        // console.log(error)
      })
  })
}

export const getLandlordRooms = ({ commit }, data) => {
  const rooms = data.rooms
  const landlordId = data.id
  const landlordRooms = []

  rooms.forEach((room) => {
    if (room.user_id === landlordId) {
      landlordRooms.push(room)
    }
  })
  commit('SET_LANDLORD_ROOMS', landlordRooms)
}

export const setRoomToEdit = ({ commit, state }, id) => {
  const room = state.rooms.find((room) => room.id === parseInt(id))
  commit('SET_ROOM_TO_EDIT', room)
}

export const updateRoom = ({ commit, state }, fd) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/update-room', fd)
      .then((response) => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
        // console.log('Room update failed')
        // console.log(error)
      })
  })
}

export const deleteRoom = ({ commit }, room) => {
  return axios.post(apiUrl + '/delete-room', room).then((response) => {
    // console.log(response.data)
  }).catch((errors) => {
    // console.log(error)
  })
}

// data for invoice and emails
export const storeCoreData = ({ commit }, coreData) => {
  commit('SET_CORE_DATA', coreData)
}

export const sendMail = ({ commit }, content) => {
  this.post(apiUrl + 'send-booked-mail', content).then((response) => {
    // console.log(response.data) success or failure
  })
}
