<template>
  <div class="client">
    <div class="person">
      <div class="person__video" ref="person">
      </div>
      <div class="person__name">
        {{ client.peer.username }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChatClient',

  data () {
    return {
      mediaStreamConstraints: {
        video: {
          frameRate: 24
        }
      },
      // webSocket: null,
      localStream: null,
      peerConnection: null,
      socket: null,
      isAudio: true,
      isVideo: true
    }
  },

  props: {
    client: Object
  },

  // methods: {
  //   addPeer () {
  //     this.$store.dispatch('addPeer', {})
  //   },
  //   sendData(data) {
  //     data.username = this.username
  //     this.socket.send(data)
  //   },
  //   createAndSetAnswer() {
  //     this.peerConnection.createAnswer((answer) => {
  //       this.peerConnection.setLocalDescription(answer)
  //       this.sendData({
  //         type: "send_answer",
  //         answer: answer
  //       })
  //     }, (error) => {
  //       console.log(error);
  //     })
  //   },
  //   handleSignallingData(data) {
  //     switch (data.type) {
  //       // client get offer and create answer
  //       case "offer":
  //         this.peerConnection.setRemoteDescription(data.offer)
  //         // create answer once got the offer
  //         this.createAndSetAnswer()
  //         break
  //       case "candidate":
  //         this.peerConnection.addIceCandidate(data.candidate)
  //         break
  //     }
  //   },
  //   joinToChat() {
  //     // get username from input box ????
  //     navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints)
  //         .then((mediaStream) => {
  //           this.localStream = mediaStream
  //           let localStream = this.localStream
  //           this.$refs.localVideo.srcObject = localStream
  //           // create peer connection with config signalling server
  //           let peerConnConfig = {
  //             iceServers: [
  //               {
  //                 "urls": [
  //                   "stun:stun.l.google.com:19302",
  //                   "stun:stun1.l.google.com:19302",
  //                   "stun:stun2.l.google.com:19302"
  //                 ]
  //               }
  //             ]
  //           }
  //           this.peerConnection = new RTCPeerConnection(peerConnConfig)
  //           // attach stream to peer connection  ... localstream ready to attach remote
  //           this.peerConnection.addStream(localStream)
  //           // peer connection listen onnaddstream of remote client to add srcObject to
  //           this.peerConnection.onaddstream = (e) => {
  //             e.this.$refs.remoteVideo.srcObject = e.mediaStream
  //           }
  //           // when we have candidate
  //           this.peerConnection.onicecandidate = (e) => {
  //             if (e.candidate === null) {
  //               return
  //             }
  //             // send the candidate to server
  //             this.sendData({
  //               type: "send_candidate",
  //               candidate: e.candidate
  //             })
  //           }
  //           // tell server, join call to that person
  //           // so that the server returns us their offer and their ice candidates
  //           this.sendData({
  //             type: "join_call"
  //           })

  //         })
  //         .catch((error) => {
  //           console.log(error)
  //         })
  //   }
  // },

  created () {
    // this.socket = new WebSocket("ws://localhost:8080")

    // this.socket.onopen = function (event) {
    //   // console.log(event)
    //   console.log('Successfully connected to the echo websocket server. Trusted: ', event.isTrusted);
    // }

    // this.socket.onmessage = (event) => {
    //   // signalling data ... event.data
    //   this.handleSignallingData(JSON.parse(event.data))
    // }
  },

  mounted () {
    this.$refs.person.appendChild(this.client.video)
  }
}
</script>

<style scoped>

</style>
