export const rooms = (state) => state.rooms

export const availableRooms = (state) => state.availableRooms

export const roomDetails = (state) => state.roomDetails

export const roomReservations = (state) => state.roomReservations

export const roomDayPrice = (state) => state.roomDetails.price

export const paidStatus = (state) => state.paidStatus

// original date
export const roomCheckinDate = (state) => state.checkin
export const roomCheckoutDate = (state) => state.checkout

// october formatted dates e.g. 2020-04-19
export const ocCheckin = (state) => state.ocDates.checkin
export const startDate = (state) => state.startDate
export const ocCheckout = (state) => state.ocDates.checkout
export const endDate = (state) => state.endDate

export const toConfirm = (state) => state.toConfirm

// data formatted to display
export const confirmationData = (state) => state.confirmationData

export const approvedUrl = (state) => state.approvedUrl

export const landlordRooms = state => state.landlordRooms

export const roomToEdit = state => state.roomToEdit

// data formatted to print, e.g. in emails
export const prStart = state => state.prStart

export const prEnd = state => state.prEnd

export const billNumber = state => state.billNumber

export const coreData = state => state.coreData
