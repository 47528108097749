<template>
  <div id="location-map">
    <div class="content" style="display: flex; align-items: center; justify-content: space-between">
      <!-- h1 style="display: none">Map Location</h1-->
      <div id="map" class="map-responsive" ref="map" style="width:100%; height: 400px;"></div>
    </div>
    <div class="room-slider center">
      <room-slides></room-slides>
    </div>
    <div class="footer-section">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import RoomSlides from '@/components/rooms/RoomSlides'
import Footer from '@/components/home/Footer'
const iconsUrl = process.env.VUE_APP_BASE_ICONS
const google = window.google
export default {
  name: 'LocationMap',
  components: {
    RoomSlides,
    Footer
  },
  data () {
    return {
      rooms: null,
      map: null
      // centerCoords: {
      //   lat: 50.73743,
      //   lng: 7.0982068
      // },
      // zoom: 10
    }
  },
  beforeCreate () {
    if (!navigator.geolocation) {
      alert('Geolocation API is not Supported from this Browser!')
    }
  },
  created () {
    this.rooms = this.$store.getters['rooms/rooms']
  },

  beforeMount () {
    // console.log('Before Mount ...')
  },

  mounted () {
    // console.log('mounted')
    this.showCenteredMap(this.centerCoords.lat, this.centerCoords.lng)
    this.addRoomsMarkers(this.rooms)
    this.map.addListener('dragend', this.storeMapEndState)
    this.map.addListener('zoom_changed', () => {
      localStorage.zoom = this.map.getZoom()
      console.log('Listener: ' + this.zoom)
    })
    this.map.addListener('center_changed', () => {
      this.centerCoords.lat = this.map.getCenter().lat().toFixed(6)
      this.centerCoords.lng = this.map.getCenter().lng().toFixed(6)
    })
  },

  beforeUpdate () {
    //
  },

  updated () {
    //
  },

  computed: {
    centerCoords () {
      if (!localStorage.center) {
        return {
          lat: 50.73743,
          lng: 7.0982068
        }
      }
      let coords = JSON.parse(localStorage.center)
      return {
        lat: parseFloat(coords.lat),
        lng: parseFloat(coords.lng)
      }
    },
    zoom () {
      if (!localStorage.zoom) {
        return 10
      }
      return parseInt(localStorage.zoom)
    }
  },
  methods: {
    storeMapEndState () {
      let center = {
        lat: this.centerCoords.lat,
        lng: this.centerCoords.lng
      }
      localStorage.center = JSON.stringify(center)
    },
    addRoomsMarkers (rooms) {
      for (let i = 0; i < rooms.length; i++) {
        let latitude = rooms[i].latitude
        let longitude = rooms[i].longitude
        let content = rooms[i].slug
        this.addMarker({ coords: { lat: latitude, lng: longitude },
          icon: `${iconsUrl}/ot3.png`,
          content: content })
      }
    },
    addMarker (data) {
      // eslint-disable-next-line no-new
      let marker = new google.maps.Marker({
        position: data.coords,
        map: this.map
      })
      if (data.icon) {
        marker.setIcon(data.icon)
      }
      if (data.content) {
        let markerInfo = new google.maps.InfoWindow({
          content: data.content
        })
        marker.addListener('mouseover', () => {
          markerInfo.open(this.map, marker)
        })
        marker.addListener('mouseout', () => {
          markerInfo.close(this.map, marker)
        })
      }
    },
    showCenteredMap (lat, lng) {
      // divMap: the position where to show map. options: consider center coords for the map
      const divMap = this.$refs['map']
      let options = {
        zoom: this.zoom,
        center: new google.maps.LatLng(lat, lng),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }
      // Map(position, options)
      this.map = new google.maps.Map(divMap, options)

      // Marker of Bonn Center
      const bonnGeoLocation = { lat: 50.73743, lng: 7.0982068 }
      this.addMarker({ coords: bonnGeoLocation,
        // icon: 'http://localhost:8080/icons/bonn.png',
        icon: `${iconsUrl}/bonn.png`,
        content: 'Bundesstadt Bonn' })

      // Marker of VTC-Net
      const vtcGeoLocation = { lat: 50.6750103, lng: 7.1459794 }
      this.addMarker({ coords: vtcGeoLocation,
        icon: `${iconsUrl}/ot3.png`,
        content: 'VTC-Net' })
    }
  }
}
</script>

<style scoped>

</style>
