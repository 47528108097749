/*  jshint -W024 */

export default [
  {
    path: 'rooms/:id',
    name: 'roomDetails',
    component: () => import(/* webpackChunkName: "rooms" */ '@/components/rooms/RoomDetails.vue'),
    meta: {
      guest: true,
      needsAuth: false
    }
  },
  {
    path: 'reservation',
    name: 'reservation',
    component: () => import(/* webpackChunkName: "reservation" */ '@/components/rooms/Reservation.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  },
  // deprecated 06.07.2021
  // {
  //   path: 'confirmation',
  //   name: 'confirmation',
  //   component: () => import(/* webpackChunkName: "confirmation" */ '@/components/rooms/partials/Confirmation.vue'),
  //   meta: {
  //     guest: true,
  //     needsAuth: true
  //   }
  // },
  {
    path: 'payment/paypal',
    name: 'paypal',
    component: () => import(/* webpackChunkName: "paypal" */ '@/components/rooms/payments/Paypal.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  },
  {
    path: 'payment/prepayment',
    name: 'prepayment',
    component: () => import(/* webpackChunkName: "prepayment" */ '@/components/rooms/payments/Prepayment.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  },
  {
    path: 'payment/cc',
    name: 'cc',
    component: () => import(/* webpackChunkName: "cc" */ '@/components/rooms/payments/CreditCard.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  },
  {
    path: 'payment-mail',
    name: 'payment-mail',
    component: () => import(/* webpackChunkName: "payment-mail" */ '@/components/rooms/payments/PaymentMail.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  }
]
