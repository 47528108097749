<template>
  <div id="add-guide">
    <h4 class="center grey-text text-darken-2 font-title-size">{{ $t('room.field_help') }}</h4>
    <div class="card-panel grey lighten-3 grey-text text-darken-2">
      <p v-html="$t('room.title_help' )"></p>
      <p v-html="$t('room.description_environment')"></p>
      <p v-html="$t('room.description_equipment')"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddGuide'
}
</script>

<style scoped>
.card-panel{
  min-height: 200px;
}
</style>
