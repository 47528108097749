import Home from '@/components/home/Home'

export default [
  {
    path: '',
    component: Home,
    name: 'home',
    meta: {
      guest: true,
      needsAuth: false
    }
  }
]
