<template>
  <div id="home" class="home">
    <div class="landlord-section" v-if="(user_role === 'landlord') && authenticated">
      <RoomManagement></RoomManagement>
    </div>
    <div v-else>
      <div id="header-slide" class="header center">
        <header-slider></header-slider>
        <div id="availability-box">
          <AvailabilityForm></AvailabilityForm>
        </div>
      </div>
      <!--div class="content"></div-->
      <div class="room-slider center">
        <room-slides></room-slides>
      </div>
      <div id="filtered-rooms">
        <dates-filtered-rooms></dates-filtered-rooms>
      </div>
    </div>
    <div class="footer-section">
      <Footer></Footer>
    </div>
    <div class="fixed-action-btn">
      <a href="#"
         class="btn-floating orange lighten-2 btn-large tooltipped"
         data-position="left" :data-tooltip="$t('nav.to_top')" Style="opacity:0.7">
        <i class="material-icons">navigation</i>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */

import AvailabilityForm from '@/components/rooms/partials/AvailabilityForm'
import HeaderSlider from './partials/HeaderSlider'
import Footer from '@/components/home/Footer'
import RoomSlides from '@/components/rooms/RoomSlides'
import DatesFilteredRooms from '@/components/rooms/DatesFilteredRooms'
import RoomManagement from '@/components/rooms/management/RoomManagement'

export default {
  name: 'Home',
  components: {
    RoomManagement,
    Footer,
    HeaderSlider,
    RoomSlides,
    DatesFilteredRooms,
    AvailabilityForm
  },

  beforeMount () {
    //
  },
  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
    // window.getElementById('Browser:Reload').setAttribute('disabled', 'true')
  },
  computed: {
    authenticated () {
      return !!this.$store.getters['auth/authenticated']
    },
    user_role () {
      return this.$store.getters['auth/userRole']
    }
  }
}
</script>
<style scoped>
  .landlord-section {
    display: block;
    min-height: 200px;
  }

  #header-slide{
    width: 100%;
    display: block;
    position: relative;
  }

  #header-slide #site-navigation{
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 9;
  }

  #header-slide #criteria-box {
    width: 100%;
    top: 100px;
    position: absolute;
    z-index: 9;
  }

  #header-slide #availability-box {
    width: 100%;
    top: 10px;
    position: absolute;
    z-index: 9;
  }
</style>
