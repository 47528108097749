<template>
  <div id="chat">
    <div class="chat-init">
      <h1>Video Chat</h1>
      <!--div class="input-field"-->
      <div class="input-field"  style="display:none;">
        <label for="chat-room" class="active">Room ID</label>
        <input type="text" class="input" id="chat-room" v-model.trim="room">
      </div>
      <div class="input-field">
        <label for="chat-user" class="active">Your Name</label>
        <input type="text" class="input" id="chat-user" v-model.trim="name">
      </div>
      <div class="center">
        <a
            href="#" class="btn waves-effect waves-light orange lighten-2"
            name="join" @click="join">Join
          <i class="material-icons left">surround_sound</i>
        </a>
      </div>
      <div class="center red-text to-del-on-deploy">
          <br>
          Die Videokonferenz ist in Protyp-Vesionnen nicht akive ,
          <br>
          sie ist nur zur Kommunikation zwischen angemeldeten Benutzern<br>
          -------------------------------------------------------------------------------
          <br>
          The video chat dose not work in prototype versions,<br>
          it is only to use between authenticated users.
      </div>
    </div>
  </div>
</template>

<script>
import { v4 } from 'uuid'
export default {
  name: 'ChatInit',
  data () {
    return {
      // if room id not exist, create one with v4()
      room: this.$route.query.room || v4(),
      name: ''
    }
  },

  mounted () {
    // console.log(this.room)
  },

  computed: {
    username () {
      return this.$store.getters['chat/getInitUsername']
    }
  },

  methods: {
    join () {
      this.$store.dispatch('chat/initUser', this.name)

      if (this.name) {
        this.$router.push({
          name: 'chat-room',
          params: {
            room: this.room
          }
        })
      } else {
        alert('Username Failed')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-init{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-top: 60px;
}
.join{
  width: 100%;
  max-width: 400px;

}

label{
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}
.input{
  font: inherit;
  outline: none;
  width: 100%;
}
</style>
