<template>
  <div id="page-footer" class="scrollspy">
    <footer class="page-footer grey darken-3">
      <div class="footer-container" id="footer-container">
        <div class="row">
          <div class="col s12 l12 left-align">
            <!--h5 class="orange-text text-lighten-2">Bonn Apartments&#x00AE;</h5-->
            <h5 class="orange-text text-lighten-2">Bonn Apartments</h5>
            <p class="blue-grey-text text-lighten-3" v-html="$t('footer.header_text')">
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col s12 l10">
            <h6 class="center blue-grey-text text-lighten-3">Bonn Apartments</h6>
            <ul class="collapsible popout z-depth-0" style="border: none;" data-collapsible="accordion">
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="far fa-handshake"></i>
                  <span>{{ $t('contact') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <contact  @close-expanded="closeSection"></contact>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons">people_outline</i>
                  <span>{{ $t('conference') }} & {{ $t('events') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <ConferenceRoom></ConferenceRoom>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons">subway</i>
                  <span>{{ $t('tour') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">Lorem ipsum dolor sit amet, consectetur.</div>
              </li>
              <li class="row">
                <div class="collapsible-header grey lighten-3 grey-text text-darken-4">
                  <i class="material-icons">local_hospital</i>
                  <span>{{ $t('medical') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut, minima?</div>
              </li>
              <!-- empty line -->
              <li class="row">
                <div class="center blue-grey-text text-lighten-3">
                  <h6>{{ $t('footer.legal_requirement')}}</h6>
                </div>
              </li>
              <!-- legal requirements -->
              <!-- Imprint, General Terms and Conditions, Privacy Statement -->
              <li class="row">
                <div class="collapsible-header  grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('read')">{{ $t('imprint') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <div id="file-imprint" class="legal-container">
                    <view-pdf :filename="imprintFilename"></view-pdf>
                  </div>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header  grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('read_download')">{{ $t('term_condition') + ' (AGB)' }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <div id="file-agb" class="legal-container">
                    <view-pdf :filename="agbFilename"></view-pdf>
                  </div>
                  <div class="center" style="margin-top: 20px">
                    <a href="#" class="btn orange lighten-2 text-white z-depth-0"
                       @click="download(agbFilename)"
                    >
                      <i class="fas fa-download"></i> {{ $t('download') }}
                    </a>
                  </div>
                </div>
              </li>
              <li class="row">
                <div class="collapsible-header  grey lighten-3 grey-text text-darken-4">
                  <span class="tooltipped" data-position="right" :data-tooltip="$t('read_download')">{{ $t('privacy') }}</span>
                  <i class="col s1 l1 material-icons change-direction">expand_less</i>
                </div>
                <div class="collapsible-body white grey-text text-darken-2">
                  <div id="file-dsgvo" class="legal-container">
                    <view-pdf :filename="dsgvoFilename"></view-pdf>
                  </div>
                  <div class="center" style="margin-top: 20px">
                    <a href="#" class="btn orange lighten-2 text-white z-depth-0"
                       @click="download(dsgvoFilename)"
                    >
                      <i class="fas fa-download"></i> {{ $t('download') }}
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <!-- Social media -->
          <div class="col s12 l2">
            <h6 class="center blue-grey-text text-lighten-3">{{ $t('in_touch') }}</h6>
            <ul class="center">
              <li><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3 m-1" data-position="left" data-tooltip="Facebook" style="margin-top: 2px"><i class="fab fa-facebook"></i></a></li>
              <li><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Twitter"  style="margin-top: 2px"><i class="fab fa-twitter"></i></a></li>
              <li><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Linked In"  style="margin-top: 2px"><i class="fab fa-linkedin"></i></a></li>
              <li><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Instagram"  style="margin-top: 2px"><i class="fab fa-instagram"></i></a></li>
              <li><a href="#" class="grey-text text-lighten-3 tooltipped btn-floating btn-small blue-grey lighten-3" data-position="left" data-tooltip="Xing"  style="margin-top: 2px"><i class="fab fa-xing"></i></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright darken-4" style="border-top:1px dotted #fff">
        <div class="container center-align"><a class="white-text" href="mailto:jarrar@vtc-net.de">vtc-Net </a>&copy;</div>
      </div>
    </footer>
  </div>
</template>

<script>
/* eslint-disable */
import ConferenceRoom from './partials/ConferenceRoom'
import Contact from './partials/Contact'
import ViewPdf from '@/misc/ViewPdf'

export default {
  name: 'Footer',
  data () {
    return {
      downloadDsgvo: false,
      downloadAgb: false
    }
  },

  components: {
    ConferenceRoom,
    Contact,
    ViewPdf
  },

  methods: {
    imprintFile (lang) {
      if (lang === 'en') {
        return 'imprint.pdf'
      }
      return 'impressum.pdf'
    },
    agbFile (lang) {
      if (lang === 'en') {
        return 'generalTerms.pdf'
      }
      return 'agb.pdf'
    },
    dsgvoFile (lang) {
      if (lang === 'en') {
        return 'privacy.pdf'
      }
      return 'dsgvo.pdf'
    },
    download (filename) {
      this.$store.dispatch('home/downloadFile', filename)
    },
    getExpandedSectionIndex () {
      const elems = document.querySelectorAll("ul.collapsible li")
      // console.log(elems);
      for (let i = 0; i < elems.length; i++) {
        if (elems[i].className === "active") {
          return i;
        }
      }
    },

    closeSection () {
      $('.collapsible').collapsible('close', this.eIndex)
    }
  },

  computed: {
    language () {
      return this.$i18n.locale
    },
    imprintFilename () {
      return this.imprintFile(this.language)
    },
    agbFilename () {
      return this.agbFile(this.language)
    },
    dsgvoFilename () {
      return this.dsgvoFile(this.language)
    },

    // collapsible:  expanded index
    eIndex () {
      return this.getExpandedSectionIndex();
    }
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
      $('.collapsible').collapsible()
      $('.scrollspy').scrollSpy()
    })
  }
}
</script>

<style scoped>
  .collapsible-body{
    padding-top: 1rem;
  }
  li.active .change-direction {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }
  .legal-container {
    width: 100%;
    overflow: auto;
    border: solid 3px;
  }
</style>
