<template>
  <div id="modal-register" class="modal">
    <div class="modal-content">
      <div class="row" style="margin-left: 0; margin-right: 0;">
        <h4 class="grey-text text-darken-2">Bonn Apartments</h4>
        <span v-if="isRenter" style="font-size: 0.8rem">{{ $t('nav.register_renter') }}</span>
        <span v-if="isLandlord" style="font-size: 0.8rem">{{ $t('nav.register_landlord') }}</span>
        <form id="register-form" role="form" @submit.prevent="registerUser">
          <div class="row" style="margin-bottom: 5px; margin-top: 5px;">
            <div class="col s12 l3" style="margin-left:0; margin-right: 0">
              <label>{{ $t('salutation') }}</label>
              <select class = "browser-default" v-model="profile.gender">
                <option value="male" selected>{{ $t('male') }}</option>
                <option value="female">{{ $t('female') }}</option>
              </select>
            </div>
            <div class="input-field col s12 l4" style="margin-left:0; margin-right: 0">
              <input type="text" id="name" class="validate" v-model="name" required>
              <label for="name">{{ $t('nav.name') }}</label>
            </div>
            <div class="input-field col s12 l5">
              <input type="text" id="surname" class="validate" v-model="surname" required>
              <label for="surname">{{ $t('nav.surname') }}</label>
            </div>
          </div>
          <div class="input-field col s12 m12">
            <input type="text" id="organization" v-model="profile.organization">
            <label for="organization">{{ $t('nav.organization') }}</label>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12">
              <input type="email" id="email" class="validate" v-model="email" required>
              <label for="email">Email</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="phone" class="validate" v-model="profile.phone" required>
              <label for="phone">{{ $t('nav.phone') }}</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="mobile" class="validate" v-model="profile.mobile">
              <label for="mobile">{{ $t('nav.mobile') }}</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="fax" class="validate" v-model="profile.fax">
              <label for="fax">{{ $t('nav.fax') }}</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="col s12 m6 tooltipped" data-position="top" :data-tooltip="$t('nav.click_write')">
              <country-select
                :label="$t('nav.country_select')"
                :countries="countries"
                @onSelect="setSelectedCountry"
              >
              </country-select>
            </div>
            <div class="input-field col s4 m2">
              <input type="text" id="postal-code" class="validate" v-model="profile.postal" required>
              <label for="postal-code">{{ $t('nav.postal') }}</label>
            </div>
            <div class="input-field col s8 m4  tooltipped" data-position="top" :data-tooltip="$t('nav.physical_address')">
              <input type="text" id="location" class="validate" v-model="profile.location" required>
              <label for="location">{{ $t('nav.location') }}</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s8">
              <input type="text" id="street" class="validate" v-model="profile.street" required>
              <label for="street">{{ $t('nav.street') }}</label>
            </div>
            <div class="input-field col s4">
              <input type="text" id="house-number" class="validate" v-model="profile.house_number" required>
              <label for="house-number">{{ $t('nav.number') }}</label>
            </div>
          </div>
          <div class="row" style="margin-bottom: 5px;">
            <div class="input-field col s12 l6">
              <input type="password" id="password" class="validate" minlength="7" v-model="password" required>
              <label for="password">{{ $t('nav.password') }}</label>
            </div>
            <div class="input-field col s12 l6">
              <input type="password" id="password_confirmation" class="validate" minlength="7"
                     v-model="password_confirmation" required>
              <label for="password_confirmation">{{ $t('nav.confirm_password') }}</label>
            </div>
          </div>
          <button type="submit" class="btn grey darken-2 z-depth-0">
            {{ $t('nav.register') }}
          </button><span class="transparent-text">&nbsp;&nbsp;</span>
          <a id="close-register" class="modal-action modal-close btn grey z-depth-0" @click="closeOverlay">
            <i class="fa fa-lock"></i> {{ $t('cancel') }}
          </a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import countries from '@/assets/js/countries'
import CountrySelect from './partials/CountrySelect'

export default {
  data () {
    return {
      name: null,
      surname: null,
      username: null,
      email: null,
      password: null,
      password_confirmation: null,

      profile: {
        gender: 'male',
        user_id: null,
        role: null,
        organization: null,
        phone: null,
        mobile: null,
        fax: null,
        country: null,
        postal: null,
        location: null,
        street: null,
        house_number: null
      },

      user_id: null,
      error: '',
      countries: []
    }
  },

  components: {
    CountrySelect
  },

  beforeMount () {
    //
  },

  mounted () {
    this.countries = countries
    this.$nextTick(() => {
      $('.modal').modal({
        inDuration: 500,
        outDuration: 500
      })
    })
    $('.tooltipped').tooltip()
  },
  updated () {
    //
  },

  methods: {
    closeOverlay () {
      this.$emit('onColseOverlay')
    },
    setSelectedCountry (country) {
      // console.log('Country to Register: ' + country)
      this.profile.country = country
      // console.log('Country to Register: ' + country)
      // this.modal = false
    },

    async loginUser () {
      const logUser = new URLSearchParams()
      logUser.append('login', this.email)
      logUser.append('password', this.password)
      await this.$store.dispatch('auth/loginUser', logUser).then((response) => {
        // console.log(response.data.user)
        this.user_id = response.data.user.id
        this.error = ''
        // console.log('In LOGIN after registration')
      }, error => {
        this.error = error.response.data.error
        // console.log(this.error)
      })
    },

    // put profile data in vuex store
    async getUserProfile () {
      await this.$store.dispatch('auth/getUserProfile', this.user_id).then(() => {
        // console.log('GET USER PROFILE')
        // console.log(this.$store.getters['auth/profile'])
        // reload is required to set registered user beside avatar of user in Sidebar menu
        document.location.reload()
        // return
      })
    },

    async registerUser () {
      const user = new URLSearchParams()
      const modal = document.querySelector('#modal-register')

      user.append('name', this.name)
      user.append('surname', this.surname)
      user.append('username', this.name + ' ' + this.surname)
      user.append('email', this.email)
      user.append('password', this.password)
      user.append('password_confirmation', this.password_confirmation)

      await this.$store.dispatch('auth/registerUser', user).then(() => {
        this.loginUser()
        // console.log('LOGIN after registration')
      })
      M.toast({ html: this.$i18n.t('nav.registered_success'), classes: 'rounded orange-text' })
      M.Modal.getInstance(modal).close()
    },
    async setUserProfile (profile) {
      await this.$store.dispatch('auth/setUserProfile', profile).then(() => {
        this.getUserProfile()
      })
    }
  },

  computed: {
    isLandlord () {
      if (this.$store.getters['auth/register_user_role'] === 'landlord') {
        return true
      }
      return false
    },

    isRenter () {
      if (this.$store.getters['auth/register_user_role'] === 'renter') {
        return true
      }
      return false
    }
  },

  watch: {
    user_id () {
      this.profile.user_id = this.user_id
      this.profile.role = this.$store.getters['auth/register_user_role']
      this.setUserProfile(this.profile)
    }
  }
}
</script>

<style scoped>

</style>
