<template>
  <div class="input-field row">

      <input type="text"
             id="select-country"
             v-model="country"
             autocomplete="off"
             @focus="show_countries=true"
             @keyup="setFilteredSelection"
             :placeholder="$t('click_here')"
             required
      >
      <label for="select-country">{{ label }}</label>
      <!-- Select country per mouse click only -->
      <div v-if="!filtered_countries && show_countries">
        <ul>
          <li v-for="(country, index) in countries" :key="index"
              class="country grey lighten-2"
              @click="setCountry(country)">
            {{ country }}
          </li>
        </ul>
      </div>
      <!-- Select countries per key then a country of filtered items with mouse click -->
      <div v-if="filtered_countries && !show_countries">
        <ul>
          <li v-for="(country, index) in filteredCountries" :key="index"
              class="country grey lighten-2"
              @click="setCountry(country)">
            {{ country }}
          </li>
        </ul>
      </div>

  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'CountrySelect',

  data () {
    return {
      country: '',
      show_countries: false,
      filtered_countries: false,
      filteredCountries: []
    }
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    countries: {
      type: Array,
      required: true
    }
  },

  methods: {
    setCountry (country) {
      // console.log(index)
      this.country = country
      this.$emit('onSelect', this.country)
      this.show_countries = false
      this.filtered_countries = false
    },

    getFilteredCountries () {
      this.filteredCountries = this.countries.filter(country =>
        country.toLowerCase().startsWith(this.country.toLowerCase()))
    },

    setFilteredSelection () {
      // Selection method per key first to filter countries
      this.show_countries = false
      this.filtered_countries = true
      // Selection method per mouse click only
      if (this.country.length === 0) {
        this.show_countries = true
        this.filtered_countries = false
      }

      // use keyboard to get filtered countries first
      if (this.country.length > 0) {
        this.getFilteredCountries()
        if (this.filteredCountries.length > 0) {
          this.filterd_countries = true
          this.show_countries = false
        }
      }
      // console.log('Filtered Countries: ' + this.filteredCountries)
      // console.log('Count of Countries: ' + this.filteredCountries.length)
    }
  }
}
</script>

<style scoped>
.country{
  padding-top: 3px;
  padding-bottom: 7px;
  padding-left: 10px;
  border-bottom: 1px dotted #000;
  text-align: left;
  cursor: pointer;
}

</style>
