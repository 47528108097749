<template>
  <div id="modal-forgot" class="modal">
    <div class="modal-content">
      <h4 class="grey-text text-darken-2">{{ $t('nav.forgot_password') }}</h4>
      <form id="forgot-form" role="form" @submit.prevent="forgotPassword">
        <div class="input-field">
          <input type="email" id="forgot-email" class="validate" v-model="email" required/>
          <label for="forgot-email">Email</label>
        </div>

        <button type="submit" class="btn orange lighten-2 z-depth-0">{{ $t('nav.send') }}</button><span class="transparent-text">&nbsp;&nbsp;</span>
        <a id="close-login" class="modal-action modal-close btn grey z-depth-0" @click="closeOverlay">
          <i class="fa fa-lock"></i> {{ $t('cancel') }}
        </a>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
export default {
  name: 'Forgot',
  data () {
    return {
      email: ''
    }
  },
  mounted () {
    this.$nextTick(() => {
      $('#modal-forgot').modal({
        dismissible: false,
        inDuration: 500,
        outDuration: 500
      })
    })
  },
  methods: {
    async forgotPassword () {
      const user = new URLSearchParams()
      const modal = document.querySelector('#modal-forgot')
      user.append('email', this.email)
      await this.$store.dispatch('auth/forgotPassword', user).then((response) => {
        // alert(this.$i18n.t('nav.invalid_credentials'))
        alert(this.$i18n.t('nav.check_email_box'))
        // console.log(response.data)
      }).catch((error) => {
        alert(error)
        // console.log(error)
      })
      M.Modal.getInstance(modal).close()
    },
    closeOverlay () {
      // $('.sidenav').sidenav('close')
      this.$emit('onColseOverlay')
    }
  }
}
</script>

<style scoped>

</style>
