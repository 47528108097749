<template>
  <div class="slides-container">
    <div class="slides" :style="{ width: windowWidth + 'px'}">
      <div class="grey darken-3 slides__previous" :style="{ width: slideMarginWidth + 'px' }">
        <div class="slides__previous-icon">
          <span @click="gotoPrevious" style="font-size: 1.5em"><i class="fas fa-angle-left"></i></span>
        </div>
      </div>
      <div class="slides__content"
           :style="{ marginLeft: '-' + slideInnerMarginLeft + 'px'}"
      >
        <room-slide
          v-for="(room, index) in rooms"
          :room="room" :key="index"
        >
        </room-slide>
      </div>
      <div class="grey darken-3 slides__next" :style="{ width: slideMarginWidth + 'px' }">
        <div class="slides__next-icon">
          <span @click="gotoNext" style="font-size: 1.5em"><i class="fas fa-angle-right"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import RoomSlide from './RoomSlide'

export default {
  name: 'Slides',
  data () {
    return {
      windowWidth: 0,
      singleWidth: 0,
      currentIndex: 0,
      slidesWidth: 0,
      availableWidthForSlides: 280,
      slidesProWindow: 1,
      slidesContainerWidth: 280,
      slideMarginWidth: 0
    }
  },

  props: {
    itemsPerSlide: {
      type: null,
      default: 1
    }
  },

  components: {
    RoomSlide
  },

  mounted () {
    this.$nextTick(() => {
      // this.singleWidth = this.$el.clientWidth / this.itemsPerSlide
      // this.innerWidth = this.singleWidth * this.rooms.length
      this.singleWidth = 200
      // this.slidesWidth = this.singleWidth * this.rooms.length
      // this.innerWidth = 1000;
      this.winWidth()
    })
  },
  computed: {
    rooms () {
      return this.$store.getters['rooms/rooms']
    },
    slideInnerMarginLeft () {
      return (this.currentIndex * this.singleWidth)
    }
  },
  methods: {
    gotoPrevious () {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1
      }
      if (this.currentIndex < 1) {
        document.querySelector('.slides__previous-icon').style.display = 'none'
      }
      if ((this.currentIndex + this.slidesProWindow) < this.rooms.length) {
        document.querySelector('.slides__next-icon').style.display = 'block'
      }
    },

    gotoNext () {
      if (this.currentIndex < this.rooms.length - this.slidesProWindow) {
        this.currentIndex += 1
        // console.log(this.currentIndex)
        // console.log(this.slidesProWindow)
      }
      if (this.currentIndex > 0) {
        document.querySelector('.slides__previous-icon').style.display = 'block'
      }
      if ((this.currentIndex + this.slidesProWindow) === this.rooms.length) {
        document.querySelector('.slides__next-icon').style.display = 'none'
      }
    },

    winWidth () {
      setInterval(() => {
        this.windowWidth = window.innerWidth
        /* 40px for right arrow and 40px for left */
        this.availableWidthForSlides = this.windowWidth - 80
        /* number of slides pro window */
        this.slidesProWindow = Math.floor(this.availableWidthForSlides / this.singleWidth)
        /* width for slides and arrows */
        this.slidesContainerWidth = this.slidesProWindow * this.singleWidth + 80
        /* available width for every side to put direction arrow  */
        this.slideMarginWidth = ((this.windowWidth - this.slidesContainerWidth) / 2) + 40
      }, 100)
    }
  }
}
</script>

<style lang="scss" scoped>
  .slides-container{
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .slides {
    display: flex;
    margin: 0 auto;

    &__previous {
      position: relative;
      width: 40px;
      height: 208px;
      color: #fff;
      flex-shrink: 0;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5em;
        font-weight: 600;
        cursor: pointer;
      }
    }

    &__content {
      display: flex;
      transition: margin 0.3s ease-out;
      overflow: auto;
      white-space: nowrap;

      div {
        width: 200px;
        overflow: hidden;
        flex-shrink: 0;
      }
    }

    &__next {
      position: relative;
      width: 40px;
      height: 208px;
      color: #fff;
      flex-shrink: 0;

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 2.5em;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
</style>
