import axios from 'axios'

const apiUrl = process.env.VUE_APP_API_BASE_URL
const appUrl = process.env.VUE_APP_BASE_URL

export const setStartDate = ({ commit, state }, start) => {
  commit('SET_START_DATE', start)
  // localStorage.setItem('startDate', start)
}

export const setEndDate = ({ commit, state }, end) => {
  commit('SET_END_DATE', end)
  // localStorage.setItem('endDate', end)
}

export const setConferenceBegin = ({ commit, state }, start) => {
  commit('SET_CONFERENCE_BEGIN', start)
}

export const setConferenceEnd = ({ commit, state }, end) => {
  commit('SET_CONFERENCE_END', end)
}

export const setConferenceData = ({ commit, state }, data) => {
  // TODO: store data in DB incl. offerId
  //       send email to requester ... maybe php mail !!!!
  //       then store data in store
  commit('SET_CONFERENCE_DATA', data) // data without offerId
}

export const sendContactForm = ({ commit }, contact) => {
  // console.log(contact)  // ok
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/send-contact-mail', contact)
      .then((response) => {
        // console.log('SUCCESS')
        // console.log(response.data)
        resolve(response)
      }).catch((error) => {
        // console.log('ERROR')
        // console.log(error)
        reject(error)
      })
  })
}

export const downloadFile = ({ commit }, filename) => {
  let url = appUrl + '/static/' + filename
  axios.get(url, { responseType: 'blob' })
    .then(response => {
      const headers = response.headers
      let blob = new Blob([response.data], { type: headers['content-type'] })
      // 'a': node for anchor element
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = filename
      link.click()
      link.remove()
    })
    .catch(() => {
      alert(this.$i18n.t('download_err'))
    })
}
