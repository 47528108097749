<template>
  <div id="filtered-room" v-if="roomsCount > 0">
    <img :src="room.image.path" class="room-image responsive-img materialboxed" alt="">
    <div class="room-price">
      <b>{{ $t('room.by_day') }} € {{ room.price }} Euro</b>
      <span :style="{marginLeft: 30 + 'px'}"></span>{{ $tc('room.persons', room.persons) }}
    </div>
    <div class="filtered-room-footer">
      <ul class="collapsible z-depth-0" style="border: none;" data-collapsible="accordion">
        <li class="row">
          <div class="go-on collapsible-header grey waves-effect waves-light z-depth-0 white-text">
            <!-- i class="material-icons">check_box_outline_blank</i-->
            <span>{{ $t('room.details') }}</span>
            <i class="col s1 l1 material-icons change-direction">expand_less</i>
          </div>
          <div class="collapsible-body white grey-text text-darken-2"><span>{{ room.description }}</span>
          </div>
        </li>
      </ul>
      <div>
        <a href="" @click="goToHome"
           class="tooltipped btn-small grey waves-effect waves-light z-depth-0"
           data-tooltip="Back to home without booking." data-position="right"
           style="width: 130px; font-size: 0.8em; margin-right: 4px">
          <i class="fas fa-angle-double-left left"></i>
          {{ $t('back') }}
        </a>
        <a href="#" @click="confirmation"
           class="tooltipped btn-small orange lighten-2 waves-effect waves-light z-depth-0"
           :data-tooltip="$t('continue_reservation')" data-position="right"
           style="width: 130px; font-size: 0.8em">
          {{ $t('continue') }}
          <i class="fas fa-angle-double-right right"></i>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import homeMixin from '@/mixins/homeMixin'
import { DateTime } from 'luxon'

export default {
  name: 'FilteredRoom',

  data () {
    return {
      //
    }
  },
  props: {
    room: {
      type: Object
    },
    roomsCount: {
      type: Number,
      default: 0
    },
    roomIndex: {
      type: Number,
      default: 0
    }
  },

  methods: {
    confirmation () {
      // console.log('Confirmation in FilteredRoom')
      let checkIn = new Date(this.$store.getters['rooms/startDate'])
      let checkOut = new Date(this.$store.getters['rooms/endDate'])
      // console.log(checkOut.toISOString())
      let start = DateTime.fromISO(checkIn.toISOString()).toFormat('yyyy/MM/dd')
      // console.log('Start: ' + start)
      const end = DateTime.fromISO(checkOut.toISOString()).toFormat('yyyy/MM/dd')
      // console.log('End: ' + end)
      // alert(this.room.id)
      this.$store.dispatch('rooms/setRoomDetails', this.room.id)
      this.$store.dispatch('rooms/setConfirmDates', { start, end })
      this.getConfirmStatus ? this.$router.push({ name: 'reservation' }) : this.goToHome()
    }
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
      $('.collapsible').collapsible()
    })
  },
  computed: {
    getConfirmStatus(){
      return this.$store.getters['rooms/toConfirm']
    }
  },
  mixins: [homeMixin]
}
</script>

<style lang="scss" scoped>
  #filtered-room {
    display: flex;
    flex-flow: column nowrap;
    flex-basis: 350px;
    max-width: 367px;
    padding: 10px;
    border: 1px solid #eee;
    margin: 10px;
  }
  .room-price{
    margin: 10px;
  }

  li.active .change-direction {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }

  .go-on{
    padding: 3px 5px;
    border-radius: 3px;
  }

  .filtered-room-footer {
    margin: auto auto 0 auto;
  }
</style>
