<template>
  <div class="rooms-container" v-show="isDateSet">
    <h4>{{ $t('room.available_rooms' )}}</h4>
    <h5 class="flow-text">{{ $t('between') }} {{ $d(new Date(ocCheckin), 'short') }} {{ $t('and') }} {{  $d(new Date(ocCheckout), 'short') }}</h5>
    <div class="filtered-rooms" v-if="roomsCount > 0">
      <filteredRoom
        v-for="(room, index) in rooms"
        :room="room"
        :roomIndex="index"
        :key="index"
        :roomsCount="roomsCount"
      >
      </filteredRoom>
    </div>
    <div v-else>
      <p class="flow-text">{{ $t('room.available_no')}} {{ $t('between') }} {{  $d(new Date(ocCheckin), 'short') }} {{ $t('and') }} {{ $d(new Date(ocCheckout), 'short') }}</p>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { DateTime } from 'luxon'
import { eventBusManager } from '@/main'
import { eventResetFilteredRooms } from '@/main'
import filteredRoom from './FilteredRoom'

export default {
  name: 'DatesFilteredRooms',
  components: {
    filteredRoom
  },
  data () {
    return {
      checkin: '',
      checkout: '',
      ocCheckin: null,
      ocCheckout: null,
      startDateFormatted: '',
      endDateFormatted: '',
      roomIndex: 0,
      roomsPerRow: 3
    }
  },

  beforeMount () {
    eventBusManager.$on('onFilterRooms', () => {
      this.checkin = this.$store.getters['rooms/startDate']
      // e.g. start date: 2020-10-21
      this.checkout = this.$store.getters['rooms/endDate']
      this.ocCheckin = this.$store.getters['rooms/ocCheckin']
      // console.log(this.ocCheckin)
      this.ocCheckout = this.$store.getters['rooms/ocCheckout']
      this.startDateFormatted = DateTime.fromISO(this.checkin).toFormat('dd. LLL yyyy')
      this.endDateFormatted = DateTime.fromISO(this.checkout).toFormat('dd. LLL yyyy')
      let checkin = this.checkin // e.g. CHECKIN: 2020-10-23
      let checkout = this.checkout
      this.$store.dispatch('rooms/getAvailableRooms', { checkin, checkout })
    })
  },

  updated() {
    eventResetFilteredRooms.$on('onResetFilteredRooms', () => {
      this.checkin = ''
      this.checkout = ''
      this.rooms.length = 0
    })
  },

  computed: {
    isDateSet () {
      let ret = false
      if ((this.checkin === null || this.checkin === '') || (this.checkout === null || this.checkout === '')) {
        ret = false
      } else {
        ret = true
      }
      // console.log (ret)
      return ret
    },

    rooms () {
      return this.$store.getters['rooms/availableRooms']
    },

    roomsCount () {
      return this.rooms.length
    }
  }
}
</script>

<style scoped>
  .rooms-container{
    width: 100%;
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .filtered-rooms{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
</style>
