import Location from '@/components/geo/LocationMap'

export default [
  {
    path: 'location',
    component: Location,
    name: 'location',
    meta: {
      guest: true,
      needsAuth: false
    }
  }
]
