<template>
  <div id="availability-form" class="availability-form">
    <div class="row">
      <div class="content col s12 m3">
        <span class="room-available grey-text text-darken-2">{{ $t('room.available') }}</span>
        <datepicker :mobile="'mobile'" @confirm="confirmData" @reset="resetFilteredRooms"></datepicker>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VueHotelDatepicker from '@/calendar/components/VueHotelDatepicker'
import { eventBusManager } from '@/main'
import { eventResetFilteredRooms } from '@/main'
import nowMixin from '@/mixins/nowMixin'

import {DateTime} from 'luxon'

export default {
  name: 'AvailabilityForm',

  components: {
    datepicker: VueHotelDatepicker
  },

  methods: {
    confirmData(dates){
      // dates, e,g. { start: "2020/10/21", end: "2020/10/23" }yyyy/MM/dd dates format
      this.$store.dispatch('rooms/setConfirmDates', dates)
      // console.log(dates)
      document.getElementById('filtered-rooms').scrollIntoView();
      this.toFilterRooms()
    },

    toFilterRooms() {
      // eventBusManager: instantiate in main.js,
      // here: emit event
      // to handle in in rooms/DatesFilteredRooms.vue
      eventBusManager.$emit('onFilterRooms')
    },

    resetFilteredRooms(){
      eventResetFilteredRooms.$emit('onResetFilteredRooms')
    }

  },

  mounted() {
    this.$nextTick(() => {
      $('.tooltip').tooltip()
    })
  },

  mixins: [nowMixin]
}
</script>

<style scoped>
.row .col.m3{
  width: 320px;
  max-height: 350px;
}

.room-available {
  font-size: 1.2rem;
  letter-spacing: 1px;
}

.availability-form {
  margin-left: 2%;
  max-height: 200px;
}

.availability-form .content {
  background-color: white;
  opacity: 0.9;
  border-radius: 5px;
  padding-top: 12px;
}

@media screen and (max-width: 480px) {

  .row .col.m3{
    width: 100%;
  }
  .availability-form {
    margin-left: 2%;
    margin-right: 2%;
  }
}
</style>
