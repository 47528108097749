export const SET_START_DATE = (state, start) => {
  state.startDate = start.slice(0, 10)
  // console.log('Start: '+ state.startDate)  // Start: 2020-10-21
}

export const SET_END_DATE = (state, end) => {
  state.endDate = end.slice(0, 10)
  // console.log('End: ' + state.endDate)  // End: 2020-10-29
}

export const SET_CONFERENCE_BEGIN = (state, start) => {
  state.conferenceBegin = start.slice(0, 10)
}

export const SET_CONFERENCE_END = (state, end) => {
  state.conferenceEnd = end.slice(0, 10)
}

export const SET_CONFERENCE_DATA = (state, data) => {
  state.conferencePersons = data.persons
  state.conferenceRequests = data.requests
}
