<template>
<div id="landlord-room" class="center">
  <div class="row">
    <div class="col s12">
      <img :src="room.image.path" class="responsive-img" alt="">
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12">
      {{ room.title }}
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12">
      <a class="orange lighten-2 waves-effect waves-light btn tooltipped z-depth-0"
         data-tooltip="Delete chicked rooms" data-placement="top">
        <i class="material-icons left">edit</i>
        Edit
      </a>
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12">
      <div class="divider"></div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'LandlordRoom',

  props: {
    room: {
      type: Object
    },
    roomId: {
      type: Number,
      default: null
    }
  }
}
</script>

<style scoped>
.divider{
  margin: 10px 0;
}
</style>
