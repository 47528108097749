<template>
  <div id="manage-rooms">
    <div class="row">
      <div class="col s12 m8">
        <h4 class="center font-title-size">{{ $t('room.list_edit')}}</h4>
        <div class="card-panel grey lighten-3">
          <div id="landlord-room">
            <div class="row center">
              <update-room v-for="(room, index) in rooms" :room="room" :count="rooms.length" :ind="index" :key="index"></update-room>
            </div>
          </div>
        </div>
      </div>

      <!-- Area with rooms checkboxes used to delete items -->
      <!-- --------------------------- -->
      <div class="col s12 m4">
        <h4 class="center grey-text text-darken-2 font-title-size">{{ $t('room.del_area')}}</h4>
        <div class="card-panel grey lighten-3 grey-text text-darken-2" v-if="roomsCount > 0">
          <p><i class="material-icons left grey-text text-darken-2">report</i>{{ $t('room.del_checked_objects')}}</p>
          <div v-for="(room, index) in rooms" :key="index">
            <p>
              <label>
                <input type="checkbox" class="filled-in" :value="room.id" v-model="checkedItems" />
                <span>{{ room.title }}</span>
              </label>
            </p>
          </div>

          <!-- show clicked names -->
          <!-- span>Checked items: {{ checkedItems }}</span -->

          <a class="delete-button orange lighten-2 waves-effect waves-light btn tooltipped"
             @click.prevent="removeRooms"
             :data-tooltip="$t('room.del_btn_click')" data-placement="top">
            <i class="material-icons left">delete</i>
            {{ $t('delete')}}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LandlordRoom from './partials/LandlordRoom'
import CheckboxSelect from './partials/CheckboxSelect'
import UpdateRoom from '@/components/rooms/management/UpdateRoom'

export default {
  name: 'ManageRooms',

  data () {
    return {
      checkedItems: [],
      toDeleteIds: []
    }
  },

  components: {
    UpdateRoom,
    CheckboxSelect,
    LandlordRoom
  },

  methods: {
    disableCheckedItems (delArr) {
      // console.log(delArr)
      delArr.forEach((id) => {
        // console.log(id)
        this.rooms.forEach((room) => {
          let item = document.getElementsByClassName(room.slug)
          if (room.id === id) {
            item[0].classList.add("disabled")
          }
        })
      })
    },

    unDisableCheckedItems () {
      this.rooms.forEach((room) => {
        let items = document.getElementsByClassName(room.slug)
        items[0].classList.remove("disabled")
      })
    },

    async deleteRoomInDB (room) {
      await this.$store.dispatch('rooms/deleteRoom', room).then(() => {
        M.toast({ html: this.$i18n.t(room.title + 'room.deleted'), classes: 'rounded orange-text' })
      })
    },

    removeRoom (id) {
      for (let roomIndex=0; roomIndex < this.rooms.length; roomIndex++) {
        if(this.rooms[roomIndex].id === id){
          let room = this.rooms[roomIndex]
          this.rooms.splice(roomIndex, 1)
          this.deleteRoomInDB (room)
          break
        }
      }
    },

    removeRooms () {
      if (confirm("Sind Sie sicher ?")) {
        console.log('OK is pressed')
      } else {
        console.log('Cancel deleting')
        return
      }
      // remove rooms
      for (let i = 0; i < this.checkedItems.length; i++){
        // this.checkedItems[i] is the id of room to delete
        this.removeRoom(this.checkedItems[i])
      }
      this.checkedItems = []
      this.toDeleteIds = []
      // console.log('After removing: ' + this.checkedItems)
    }
  },

  created () {
    const payload = {
      'id': (this.$store.getters['auth/user']).id,
      'rooms': this.$store.getters['rooms/rooms']
    }
    this.$store.dispatch('rooms/getLandlordRooms', payload)
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  computed: {
    rooms () {
      return this.$store.getters['rooms/landlordRooms']
    },
    roomsCount () {
      return this.rooms.length
    }
  },

  watch: {
    checkedItems(delArr) {
      this.toDeleteIds = delArr
      // ! to avoid disabled of disabled
      this.unDisableCheckedItems()
      this.disableCheckedItems(delArr)
    }
  }
}
</script>

<style scoped>
.divider{
  margin: 10px 0;
}
</style>
