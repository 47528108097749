<template>
  <div id="add-room">
    <div class="row">
      <div class="col s12 m8">
        <h4 class="center grey-text text-darken-2 font-title-size font-title-size">{{ $t('room.add_room') }}</h4>
        <div class="card-panel grey grey-text text-darken-2 lighten-3">
          <div class="row">
            <div class="input-field col s12 m12">
              <input type="text" id="room-title" name="title" class="validate" minlength="5" v-model="title" required >
              <label for="room-title">{{ $t('room.title') }}</label>
            </div>
            <div class="row tooltipped" data-position="bottom" :data-tooltip="$t('nav.physical_address')"
                 style="margin-bottom: 5px;">
              <div class="input-field col s12 m6">
                <input type="text" id="country" class="validate" v-model="country" required>
                <label for="country">{{ $t('nav.country') }}</label>
              </div>
              <div class="input-field col s4 m2">
                <input type="text" id="postal-code" class="validate" v-model="postal" required>
                <label for="postal-code">{{ $t('nav.postal') }}</label>
              </div>
              <div class="input-field col s8 m4">
                <input type="text" id="location" class="validate" v-model="location" required>
                <label for="location">{{ $t('nav.location') }}</label>
              </div>
            </div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="input-field col s8">
                <input type="text" id="street" class="validate" v-model="street" required>
                <label for="street">{{ $t('nav.street') }}</label>
              </div>
              <div class="input-field col s4">
                <input type="text" id="house-number" class="validate" v-model="house_number" required>
                <label for="house-number">{{ $t('nav.number') }}</label>
              </div>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="room-price" class="validate" name="price" v-model="price" required>
              <label for="room-price">{{ $t('price_by_day') }} (in €)</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="room-person" class="validate" name="persons" v-model="persons" required>
              <label for="room-person">max. {{ $t('persons') }}</label>
            </div>
            <div class="input-field col s12 m4">
              <input type="text"
                     onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                     id="min-period" class="validate" name="min_period" v-model="min_period" required>
              <label for="min-period">min. {{ $t('room.period') }}</label>
            </div>
            <div class="input-field col s12 m12">
              <textarea class="materialize-textarea" id="room-description" name="description" v-model="description"></textarea>
              <label for="room-description">{{ $t('room.description_env') }}</label>
            </div>
            <div class="col s12 m12">
              <div class="input-field">
                <textarea class="materialize-textarea" id="room-details" name="details" v-model="details"></textarea>
                <label for="room-details">{{ $t('room.description_equ') }}</label>
              </div>
            </div>
            <div class="col s12 m7 offset-m5">
              <div class="col s12">
                <img :src="imageLocalUrl" class="right" :class="{ 'is-loaded': isLoaded }" alt="">
              </div>
              <div class="col s12">
                <a href="#"
                   class="orange-text right"
                   :class="{ 'select-room': !isLoaded }"
                   @click.prevent="onSelectFile"
                >
                  <span v-if="isLoaded" style="line-height: 1.5rem">{{ fileName }}</span>
                  <span v-if="!isLoaded">{{ $t('room.search_img')}}</span>
                </a>
                <input
                  type="file"
                  name="file"
                  style="display: none"
                  ref="selectFile"
                  accept="image/*"
                  @change="onSelectedFile"
                >
              </div>
            </div>
            <div class="col s12 m12" style="height: 12px"></div>

            <!--/div-->
            <div class="col s12 m12">
              <button @click="sendForm" class="btn-radius btn orange white-text lighten-2 waves-effect waves-light z-depth-0">Save Room
                <i class="material-icons right">storage</i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col s12 m4">
        <add-guide></add-guide>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import AddGuide from './partials/AddGuide'

export default {
  name: 'AddRoom',
  data () {
    return {
      title: '',
      slug: '',
      country: null,
      postal: null,
      location: null,
      street: null,
      house_number: null,
      price: null,
      persons: null,
      min_period: null,
      description: '',
      details: '',
      imageLocalUrl: '',
      fileName: '',
      isLoaded: false,
      image: '',
      latitude: null,
      longitude: null
    }
  },
  components: {
    AddGuide
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },

  methods: {
    // button method, which trigger a click on file input element
    onSelectFile () {
      this.isLoaded = false
      this.$refs.selectFile.click()
    },

    fileReader (file) {
      const fReader = new FileReader()
      fReader.addEventListener('load', () => {
        this.imageLocalUrl = fReader.result
      })
      // FE read the file as URL (show image on the front end)
      fReader.readAsDataURL(file)
      // console.log(fReader)
      this.isLoaded = true
    },

    onSelectedFile (event) {
      let files = event.target.files
      // file: the first file room in the event room
      let file = files[0]
      this.fileName = file.name
      // console.log(this.fileName)
      this.fileReader(file)
      // file room contain data like: name, size, type, lastModified (in ftime)
      this.image = file
    },
    setSlug () {
      if(this.title.length > 3) {
        let slugPrefix = this.title.toLowerCase().replace(/\s/g, '-')
        let randStr = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        let slugSuffix = randStr.slice(3,7)
        this.slug = slugPrefix + slugSuffix
      } else {
        alert ('Room title must be more the three character !')
      }
    },
    async getGeoLocation(){
      let postAddress = this.street + ' ' + this.house_number + ', ' + this.postal + ' ' + this.location + ', ' + this.country
      await this.$store.dispatch('geo/getGeoLocation', postAddress).then((response) => {
        this.latitude = response.data.results[0].geometry.location.lat
        this.longitude = response.data.results[0].geometry.location.lng
        console.log(response)
      }).catch((error) => {
        console.log(error.response.data.errors)
      })
    },
    sendForm () {
      this.setSlug()
      this.getGeoLocation()

      const fd = new FormData()
      fd.append('title', this.title)
      fd.append('slug', this.slug)
      fd.append('country', this.country)
      fd.append('postal', this.postal)
      fd.append('location', this.location)
      fd.append('street', this.street)
      fd.append('house_number', this.house_number)
      fd.append('price', this.price)
      fd.append('persons', this.persons)
      fd.append('description', this.description)
      fd.append('details', this.details)
      fd.append('min_period', this.min_period)
      fd.append('user_id', this.user.id)
      fd.append('type_id', 1)
      fd.append('file', this.image)
      fd.append('latitude', this.latitude)
      fd.append('longitude', this.longitude)

      this.$emit('create-room', fd)
    }
  }
}
</script>

<style scoped>
.select-room{
  padding: 1vw 1vw;
  border: dashed 2px orange;
}
.is-loaded{
  height: 150px;
}
.btn-radius {
  border-radius: 5px;
}
</style>
