<template>
  <!-- route to chart video room -->
  <a :href="`/${$i18n.locale}/chat`"
     class="tooltipped"
     data-position="bottom" :data-tooltip="$t('nav.video_chat')"
     data-target="mobile-links"
  >
    <i class="material-icons orange-text text-lighten-2">surround_sound</i>
  </a>
</template>

<script>
export default {
  name: 'ChatStarter'
}
</script>

<style scoped>

</style>
