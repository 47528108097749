/* eslint no-undef: 0 */
import axios from 'axios'
// import { ctrl } from '@/config/auth.config'

const apiUrl = process.env.VUE_APP_API_BASE_URL

export const registerUser = ({ commit }, user) => {
  return axios.post(apiUrl + '/api/auth/register', user).then(response => {
    console.log(response)
  }).catch(error => {
    console.log(error.response)
  })
}

export const loginUser = ({ commit, state }, user) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/api/auth/login', user).then(response => {
      resolve(response)
      commit('SET_TOKEN', response.data.token)
      commit('SET_USER', response.data.user)
      // console.log(response.data.user)
      commit('SET_AUTHENTICATED', true)
      // console.log(response.data.user.role)
      // console.log(response)
    }).catch((error) => {
      reject(error)
      console.log('Action Login User 2:' + error.response.data.error)
    })
  })
}

// to update user table  and profile table in DB
export const updateUser = ({ commit, state }, payload) => {
  // console.log('Action check ID: ' + payload.id)
  // console.log(payload)
  const authorization = {
    headers: {
      'Authorization': 'Bearer ' + state.token
    }
  }

  return axios.post(apiUrl + '/update-user', payload, authorization).then(response => {
    // console.log(response.data)
  }).catch(error => {
    console.log(error.response)
  })
}

// immediate after registration
export const setUserProfile = ({ commit }, profile) => {
  return axios.post(apiUrl + '/profile', profile).then(response => {
    // console.log('Profile Data to set in DB')
    // console.log(profile)
    // console.log('Set Profile Data in DB') // Profile created
    commit('SET_CUSTOMER_NUMBER', response.data)
  })
}

// in Profile plugin routes
export const getUserProfile = ({ commit }, uid) => {
  return axios.get(apiUrl + '/profile/' + uid).then((response) => {
    console.log(response.data)
    // console.log('Get Profile Data to store')
    const profile = response.data[0]
    commit('SET_PROFILE', profile)
    commit('SET_CUSTOMER_NUMBER', uid)
  })
}

export const deleteUser = ({ commit, state }, user) => {
  const authorization = {
    headers: {
      'Authorization': 'Bearer ' + state.token
    }
  }
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/delete-user', user, authorization).then((response) => {
      resolve(response)
      commit('SET_USER', {})
      console.log(response.data)
    }).catch(error => {
      reject(error)
      console.log(error.response)
    })
  })
}

export const deleteProfile = ({ commit, state }, profile) => {
  return axios.post(apiUrl + '/delete-profile', profile).then((response) => {
    commit('SET_PROFILE', null)
    console.log(response.data)
  })
}

export const logout = ({ commit, state }) => {
  commit('SET_AUTHENTICATED', false)
  commit('SET_USER', {})
  commit('SET_TOKEN', '')
  commit('SET_PROFILE', null)

  // todo: secure for later
  // BE: create table (user_id. profile_id, auth_table_id)
  // enter entry when registered
  // delete entry when logging out
  localStorage.setItem('checkin', '')
  localStorage.setItem('checkout', '')
}

// no BE required, only to store countries in the app. store
export const setCountries = ({ commit, state }, countries) => {
  // like commit here
  state.countries = countries
  console.log(this.$store.getters['auth/countries'])
}

// only on registering !!!
export const setRegisterUserRole = ({ state }, registerUserRole) => {
  state.register_user_role = registerUserRole
}

// Forgot Password axios.post(apiUrl + '/api/auth/login', data).then(response => {
export const forgotPassword = ({ commit, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/api/auth/forgot-password', data).then((response) => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

// RestPassword, data(code, password, password_confirmation)
export const resetPassword = ({ commit, state }, data) => {
  return new Promise((resolve, reject) => {
    axios.post(apiUrl + '/api/auth/reset-password', data).then((response) => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}

// Landlord and his profile to use data in paid Emails
export const getHirer = ({ commit }, uid) => {
  return axios.get(apiUrl + '/hirer/' + uid).then((response) => {
    const hirer = response.data[0]
    commit('SET_HIRER', hirer)
  })
}

// like getUserProfile, but used only to set landlord profile
export const getHirerProfile = ({ commit }, uid) => {
  return axios.get(apiUrl + '/hirer-profile/' + uid).then((response) => {
    console.log(response.data)
    // console.log('Get Profile Data to store')
    const profile = response.data[0]
    commit('SET_HIRER_PROFILE', profile)
  })
}
