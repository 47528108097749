
/*  jshint -W024 */

export default [

  {
    path: 'login',
    name: 'login',
    // component: Login,
    component: () => import(/* webpackChunkName: "login" */ '@/components/auth/Login.vue'),
    meta: {
      guest: true,
      needsAuth: true
    }
  },
  {
    path: 'register',
    name: 'register',
    // component: Register,
    component: () => import(/* webpackChunkName: "register" */ '@/components/auth/Register.vue'),
    meta: {
      guest: true,
      needsAuth: false
    }
  },
  {
    path: 'reset-password/:code',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '@/components/auth/Reset.vue'),
    meta: {
      guest: true,
      needsAuth: false
    }
  }
]
