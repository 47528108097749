
export const SET_USER = (state, user) => {
  // console.log(user)
  state.user = user
}

export const SET_TOKEN = (state, token) => {
  state.token = token
}

export const SET_AUTHENTICATED = (state, isAuth) => {
  state.authenticated = isAuth
}

export const SET_PROFILE = (state, profile) => {
  state.profile = profile
}

export const UPDATE_USER = (state, obj) => {
  const usr = state.user
  usr.name = obj.name
  usr.surname = obj.surname
  usr.username = obj.username
  usr.email = obj.email
}

export const SET_HIRER = (state, hirer) => {
  state.hirer = hirer
}

export const SET_HIRER_PROFILE = (state, profile) => {
  state.hirer_profile = profile
}

export const SET_CUSTOMER_NUMBER = (state, number) => {
  state.customerNumber = number
}
