export default {
  rooms: [],
  room: {},
  roomDetails: {},
  roomReservations: [],
  availableRooms: [],
  paidStatus: '',
  // managers: [],
  // manager: null,
  checkin: '',
  checkout: '',
  ocDates: {
    checkin: null,
    checkout: null
  },
  startDate: '',
  endDate: '',
  prStart: '',
  prEnd: '',
  confirmationData: {
    checkin: '',
    checkout: '',
    daysBetween: {},
    aDayPrice: null,
    totalPrice: null
  },
  toConfirm: false,
  token: undefined,
  approvedUrl: '',

  landlordRooms: [],

  roomToEdit: {},

  billNumber: null,

  coreData: {}
}
