<template>
  <div id="conference-room">
    <form id="formValidate">
      <div class="input-field">
        <input type="text" id="conference-begin" class="validate" v-model="from_date" placeholder="pleas insert start date an time"  required />
        <label class="active" for="conference-begin">{{ $t('from') }}</label>
      </div>
      <div class="input-field">
        <input type="text" id="conference-end" class="validate" v-model="to_date" required placeholder="pleas insert end date an time" />
        <label class="active" for="conference-end" data-error="Please enter your first name.">{{ $t('to') }}</label>
      </div>
      <div class="input-field">
        <label for="participant" class="active grey-text text-darken-2 left">{{ $t('persons') }}</label>
        <input id="participant"
               name="persons"
               type="text"
               onkeypress="return event.charCode >= 48 && event.charCode <= 57"
               v-model="persons"
               required>
      </div>

      <label for="requests">{{ $t('requests') }}</label>
      <textarea id="requests" class="grey-text text-darken-2" placeholder="Additions, Comments. Catering, Equipment, ..."></textarea>

      <div class="center-align" style="font-size: 0.8rem">{{ $t('check_email') }}</div>
      <br>
      <a class="waves-effect waves-light btn orange lighten-2 white-text"
         @click="handleRequest" disabled>{{ $t('offers') }}</a>
    </form>
  </div>
</template>

<script>
/* eslint no-undef: 0 */
import { v4 as uuidv4 } from 'uuid'
import nowMixin from '@/mixins/nowMixin'

export default {
  name: 'ConferenceRoom',
  data () {
    return {
      persons: null,
      requests: '',
      from_date: '',
      to_date: ''
    }
  },

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  components: {
    // 'datetime': Datetime
  },

  computed: {
    uniqueCode () {
      return uuidv4().substr(28)
    }
  },

  methods: {
    setConferenceBegin (value) {
      // console.log(value)
      // this.$store.dispatch('home/setConferenceBegin', value)
    },
    setConferenceEnd (value) {
      // console.log(value)
      // this.$store.dispatch('home/setConferenceEnd', value)
    },
    handleRequest () {
      /*
      this.begin = this.$store.getters['home/conferenceBeginDate']
      if (this.begin === '') {
        alert('Date filed is required')
        return
      }
      this.end = this.$store.getters['home/conferenceEndDate']
      if (this.end === '') {
        alert('Date filed is required')
        return
      }
      const num = parseInt(this.persons)
      if (num < 2) {
        alert('Check number of Persons')
        return
      }

      const payload = {
        lang: this.$i18n.locale,
        type: 'conference',
        persons: this.persons,
        requests: this.requests,
        offerId: this.uniqueCode
      }
      this.$store.dispatch('home/setConferenceData', payload)

       */
    }
  },

  mixins: [nowMixin]
}
</script>

<style scoped>

label {
  font-size: 0.9rem;
}

#conference-room.input-field > label,
.input-field > label:not(.label-icon).active {
  -webkit-transform: translateY(-14px) scale(0.9);
  transform: translateY(-14px) scale(0.9);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
</style>
