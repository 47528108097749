<template>
  <div class="room">
    <div class="people" id="people">
      <div class="person">
        <div class="person__video">
          <!--video muted id="local-video" autoplay></video-->
          <video ref="localVideo" autoplay playsinline muted></video>
        </div>
        <div class="person__name">
          {{ username }}
        </div>
      </div>
      <Person v-for="client in clients" :client="client" :key="client.peer.id"></Person>
    </div>
  </div>
</template>

<script>
import Person from '@/components/chat/ChatClient'
export default {
  name: 'ChatRoom',
  components: {
    Person
  },
  data () {
    return {
      mediaStreamConstraints: {
        video: {
          frameRate: 24
        }
      },
      localStream: null,
      peerConnection: null,
      socket: null,
      isAudio: true,
      isVideo: true
    }
  },

  // prop room via route query
  props: {
    room: String
  },

  created () {
    this.socket = new WebSocket('ws://localhost:3000')

    this.socket.onopen = function (event) {
      console.log(event)
      console.log('Successfully connected to the echo websocket server. Trusted: ', event.isTrusted)
    }

    this.socket.onmessage = (event) => {
    // signalling data ... event.data
      this.handleSignallingData(JSON.parse(event.data))
    }
  },

  methods: {
    handleSignallingData (data) {
      // switch (data.type) {
      //   case "answer":
      //     this.peerConnection.setRemoteDescription(data.answer)
      //     break
      //   case "candidate":
      //     this.peerConnection.addIceCandidate(data.candidate)
      //     break
      // }
    },
    sendData (data) {
      data.username = this.username
      this.socket.send(data)
    },

    createAndSendOffer () {
      // this.peerConnection.createOffer((offer) => {
      //   this.sendData({
      //     type: "store_offer",
      //     offer: offer
      //   })
      //   // set offer on remote connection
      //   this.peerConnection.setLocalDescription(offer)
      // }, (error) => {
      //   console.log(error)
      // })
    },

    startToChat () {
      navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints)
        .then((mediaStream) => {
          this.localStream = mediaStream
          this.$refs.localVideo.srcObject = this.localStream
          // create peer connection with config signalling server
          let peerConnConfig = {
            iceServers: [
              {
                'urls': [
                  'stun:stun.l.google.com:19302',
                  'stun:stun1.l.google.com:19302',
                  'stun:stun2.l.google.com:19302'
                ]
              }
            ]
          }
          this.peerConnection = new RTCPeerConnection(peerConnConfig)
          // attach stream to peer connection  ... localstream ready to attach remote
          this.peerConnection.addStream(this.localStream)
          // peer connection listen onnaddstream of remote client to add srcObject to
          this.peerConnection.onaddstream = (e) => {
            e.this.$refs.remoteVideo.srcObject = e.mediaStream
          }
          // when we have candidate
          this.peerConnection.onicecandidate = (e) => {
            if (e.candidate === null) {
              // return
            }
            // send the candidate to server
            // this.sendData({
            //   type: "store_candidate",
            //   candidate: e.candidate
            // })
          }
          console.log('Peer Connection')
          console.log(this.peerConnection)
          // create and send offer
          this.createAndSendOffer()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    // fired from mute audio button ( todo button 08.04.2021)
    muteAudio () {
      this.isAudio = !this.isAudio
      this.localStream.getAudioTracks()[0].enabled = this.isAudio
    },
    // fired from mute video button ( todo button 08.04.2021)
    muteVideo () {
      this.isVideo = !this.isVideo
      this.localStream.getVideoTracks()[0].enabled = this.isVideo
    }
  },

  computed: {
    username () {
      return this.$store.getters['chat/getInitUsername']
    },
    clients () {
      return this.$store.getters['chat/getClients']
    }
  },

  mounted () {
    this.startToChat(this.room)

    // * add person to room
  }
}
</script>

<style scoped lang="scss">
.room {
  height: 100%;
  position: relative;
}

.people {
  display: flex;
  flex-wrap: wrap;
  vertical-align: baseline;
  width: 100%;
  padding: 40px
}

video {
  width: 100%;
}

.person {
  position: relative;
  margin: 0 1% 60px 1%;
  width: 100%;

  &__video {
    background-color: #ccc;
    margin-bottom: 10px;
    box-shadow: 0 10px 25px rgba(49, 49, 49, 0.2);
    border-radius: 3px;
  }

  &__name {
    text-align: center;
    font-weight: 600;
  }

  @media(min-width: 576px) and (max-width: 767px) {
    width: 49%;
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(2n + 1) {
      margin-left: 0;
    }
  }

  @media(min-width: 768px) and (max-width: 991px) {
    width: 32%;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n + 1) {
      margin-left: 0;
    }
  }
  @media(min-width: 992px) {
    width: 23.5%;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(4n + 1) {
      margin-left: 0;
    }
  }
}
</style>
