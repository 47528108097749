<template>
  <div id="update-room">
    <div class="col s12">
      <img :src="room.image.path" class="responsive-img" alt="" v-if="!isImageLoaded">
      <img :src="imageLocalUrl" class="responsive-img" alt="" v-if="isImageLoaded">
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12" v-show="toEdit">
      <a href="#"
         class="orange-text orange-border tooltipped"
         data-position="top"
         :data-tooltip="$t('room.search_image')"
         @click.prevent="onSelectFile"
      >
        <!-- image from backend -->
        <span style="line-height: 1.5rem" v-if="!isImageLoaded"
        >{{ room.image.file_name }}</span>
        <!-- image from frontend -->
        <span style="line-height: 1.5rem" v-if="isImageLoaded"
        >{{ fileName }}</span>
      </a>
      <input
        type="file"
        name="file"
        style="display: none"
        ref="selectFile"
        accept="image/*"
        @change="onSelectedFile"
      >
    </div>
    <div class="col s12" v-if="!toEdit">
      {{ room.title }}
    </div>
    <div class="input-field col s12 m12" v-if="toEdit">
      <input type="text" id="room-title" name="title" class="validate" minlength="5" v-model="room.title">
      <label for="room-title" class="active">{{ $t('room.title') }}</label>
    </div>
    <div class="input-field col s12 m4" v-if="toEdit">
      <input type="text"
             onkeypress="return event.charCode >= 48 && event.charCode <= 57"
             id="room-price" class="validate" name="price" v-model="room.price" required>
      <label for="room-price" class="active">{{ $t('price_by_day') }} (in €)</label>
    </div>
    <div class="input-field col s12 m4" v-if="toEdit">
      <input type="text"
             onkeypress="return event.charCode >= 48 && event.charCode <= 57"
             id="room-person" class="validate" name="persons" v-model="room.persons" required>
      <label for="room-person" class="active">max. {{ $t('persons') }}</label>
    </div>
    <div class="input-field col s12 m4" v-if="toEdit">
      <input type="text"
             onkeypress="return event.charCode >= 48 && event.charCode <= 57"
             id="min-period" class="validate" name="min_period" v-model="room.min_period" required>
      <label for="min-period" class="active">min. {{ $t('room.period') }}</label>
    </div>
    <div class="input-field col s12 m12" v-if="toEdit">
          <textarea
            class="materialize-textarea"
            id="room-description"
            name="description"
            v-model="room.description"
          >
          </textarea>
      <label for="room-description" class="active">{{ $t('room.description_env') }}</label>
    </div>
    <div class="col s12 m12" v-if="toEdit">
      <div class="input-field">
            <textarea
              class="materialize-textarea"
              id="room-details"
              name="details"
              v-model="room.details"
            >
            </textarea>
        <label for="room-details" class="active">{{ $t('room.description_equ') }}</label>
      </div>
    </div>
    <div class="col s12 m12" v-if="toEdit">
      <button @click="updateRoom" class="btn-radius btn orange white-text lighten-2 waves-effect waves-light z-depth-0">Update Room
        <i class="material-icons right">storage</i>
      </button>
      &nbsp;
      <button @click="closeEditing" class="btn orange white-text lighten-2 waves-effect waves-light z-depth-0">
        <i class="fa fa-lock"></i> {{ $t('cancel') }}
      </button>
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12" v-if="!toEdit">
      <a id="editLink"
         class="orange lighten-2 waves-effect waves-light btn z-depth-0"
         :class="room.slug"
         @click.prevent="setRoomToEdit()"
      >
        <i class="material-icons left">edit</i>
        {{ $t('edit') }}
      </a>
    </div>
    <div class="col s12">
      <br>
    </div>
    <div class="col s12"  v-if="ind < (count - 1)">
      <div class="divider black"></div>
    </div>
    <div class="col s12"  v-if="ind < (count - 1)">
      <br>
    </div>
  </div>
</template>

<script>
/* eslint no-undef: 0 */

export default {
  name: 'UpdateRoom',

  data () {
    return {
      title: '',
      slug: '',
      storageFolderToRemove: 'no',
      imageLocalUrl: '',
      toEdit: false,
      isImageLoaded: false,
      fileName: '',
      image: ''
    }
  },

  props: ['room', 'count', 'ind'],

  mounted () {
    this.$nextTick(() => {
      $('.tooltipped').tooltip()
    })
  },

  methods: {
    setRoomToEdit () {
      // console.log('Room ID: ', id)
      this.toEdit = true
      // this.$store.dispatch('rooms/setRoomToEdit', id)
    },
    closeEditing () {
      this.toEdit = false
    },
    onSelectFile () {
      this.isImageLoaded = false
      this.$refs.selectFile.click()
    },
    fileReader (file) {
      const fReader = new FileReader()
      fReader.addEventListener('load', () => {
        this.imageLocalUrl = fReader.result
      })
      // FE read the file as URL (show image on the front end)
      fReader.readAsDataURL(file)
      // console.log(fReader)
      this.isImageLoaded = true
    },
    onSelectedFile (event) {
      let files = event.target.files
      // file: the first file room in the event room
      let file = files[0]
      this.fileName = file.name
      // console.log(this.fileName)
      // todo to test
      this.room.image.file_name = this.fileName
      this.fileReader(file)
      // file room contain data like: name, size, type, lastModified (in ftime)
      this.image = file
    },
    setSlug () {
      if (this.room.title.length > 3) {
        let slugPrefix = this.title.toLowerCase().replace(/\s/g, '-')
        let randStr = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        let slugSuffix = randStr.slice(3, 7)
        this.slug = slugPrefix + slugSuffix
      } else {
        alert('Room title must be more the three character !')
      }
    },
    async updateRoom () {
      this.title = this.room.title
      this.setSlug()
      if (this.isImageLoaded) {
        let str = this.room.image.path
        // absolute path with filename
        // console.log('absolute path with filename: ', str)
        this.storageFolderToRemove = str.substring(0, str.lastIndexOf('/'))
        console.log('absolute path without filename: ', this.storageFolderToRemove)
        let abs = this.storageFolderToRemove
        // BE folder, where public folder reside: storage/app/uploads/...
        let rel = abs.substring(abs.indexOf('uploads'))
        this.storageFolderToRemove = rel
        // console.log(this.storageFolderToRemove)
        return
      } else {
        // no: for to keep old file in BE
        this.storageFolderToRemove = 'no'
      }

      const fd = new FormData()
      fd.append('id', this.room.id)
      fd.append('title', this.room.title)
      fd.append('slug', this.room.slug)
      fd.append('price', this.room.price)
      fd.append('persons', this.room.persons)
      fd.append('description', this.room.description)
      fd.append('details', this.room.details)
      fd.append('min_period', this.room.min_period)
      fd.append('folder', this.storageFolderToRemove)
      fd.append('file', this.image)

      await this.$store.dispatch('rooms/updateRoom', fd).then((response) => {
        if (response.status === 200) {
          this.toEdit = false
        }
      })
    }
  },
  updated () {
    // console.log(this.room.image.path.substring(0, this.room.image.path.lastIndexOf('/')))
    // console.log(this.room.image.path)
  }
}
</script>

<style scoped>
  a.orange-border{
    border: dotted 2px orange;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 999;
  }
</style>
