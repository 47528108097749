<template>
<div>
  Select Checkboxes   ij test
  <div v-for="(room, index) in rooms" :key="index">
    <p>
      <label>
        <input type="checkbox" class="filled-in" :value="room.title" v-model="checkedItems" />
        <span>{{ room.title }}</span>
      </label>
    </p>
  </div>
</div>
</template>

<script>
export default {
  name: 'CheckboxSelect',

  data () {
    return {
      checkedItems: []
    }
  },

  props: {
    rooms: {
      type: Array,
      default: () => []
    }
  },

  mounted () {
    console.log('THESE ROOMS')
    console.log(this.rooms)
  }
}
</script>

<style scoped>

</style>
