<template>
<div class="header-wrapper">
  <section class="slider">
    <ul class="slides">
      <li>
          <img class="responsive-img" src="@/assets/images/rathaus.jpg">
        <!-- random image -->
        <div class="caption right-align">
          <h2 style="text-shadow: 3px 3px 3px #000">{{ $t('header.medical') }}</h2>
        </div>
      </li>
      <li>
        <img  class="responsive-img" src="@/assets/images/beethoven.jpg">
        <!-- random image -->
        <div class="caption right-align">
          <h2 style="text-shadow: 3px 3px 3px #000">{{ $t('header.culture') }}</h2>
        </div>
      </li>
      <li>
        <img class="responsive-img" src="@/assets/images/godesburg.jpg">
        <!-- random image -->
        <div class="caption right-align">
          <h2 style="text-shadow: 3px 3px 3px #000">{{ $t('header.nature') }}</h2>
        </div>
      </li>
      <li>
        <img class="responsive-img" src="@/assets/images/wccb.jpg">
        <!-- random image -->
        <div class="caption right-align">
          <h2 style="text-shadow: 3px 3px 3px #000">{{ $t('header.conferences') }}</h2>
        </div>
      </li>
    </ul>
  </section>
</div>
</template>

<script>
/* eslint no-undef: 0 */

export default {
  name: 'HomeSlider',

  mounted () {
    this.$nextTick(() => {
      $('.slider').slider({
        indicators: false,
        transition: 500,
        interval: 6000
      })
    })
  }
}
</script>

<style scoped>
  .slider .slides li img {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: inherit;
  }

  ul img{
    max-width: 100%;
    /* height: auto; */
  }

  @media screen and (max-width: 760px) {
    .caption{
      display: none;
    }
  }
</style>
