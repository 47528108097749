export const startDate = (state) => state.startDate

export const endDate = (state) => state.endDate

export const conferenceBeginDate = (state) => state.conferenceBegin

export const conferenceEndDate = (state) => state.conferenceEnd

export const conferencePersons = (state) => state.conferencePersons

export const conferenceRequests = (state) => state.conferenceRequests
