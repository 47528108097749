<template>
  <div>
    <!-- merely to get all rooms for whole application -->
  </div>
</template>

<script>
export default {
  name: 'AllRooms',

  mounted () {
    this.$store.dispatch('rooms/getAllRooms')
  }
}
</script>

<style scoped>

</style>
