export const SET_INIT_USER_NAME = (state, name) => {
  state.user.name = name
}

// export const ADD_PEER
export const SET_NEW_CLIENT = (state, { video, peer }) => {
  state.clients.push({ video, peer })
}

export const REMOVE_CLIENT = ({ state }, peer) => {
  state.clients = state.clients.filter((p) => {
    return p.peer.id !== peer.id
  })
}

export const SET_MUTED = (state, value) => {
  state.user.muted = value
}

export const SET_PAUSED = (state, value) => {
  state.user.paused = value
}
