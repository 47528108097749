import axios from 'axios'

const apiKey = process.env.VUE_APP_API_KEY

export const getGeoLocation = ({ commit }, location) => {
  return new Promise((resolve, reject) => {
    axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: {
        address: location,
        key: apiKey
      }
    }).then((response) => {
      resolve(response)
    }).catch((error) => {
      reject(error)
    })
  })
}
