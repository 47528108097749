import ChatInit from '@/components/chat/ChatInit'
import ChatRoom from '@/components/chat/ChatRoom'
import store from '@/store'

export default [
  {
    path: 'chat',
    component: ChatInit,
    name: 'chat',
    meta: {
      guest: true,
      needsAuth: false
    }
  },
  {
    path: '/chat/:room',
    component: ChatRoom,
    name: 'chat-room',
    props: true,
    meta: {
      guest: true,
      needsAuth: false
    },
    beforeEnter: (to, from, next) => {
      if (!store.getters['chat/getInitUsername']) {
        // no name, then go to ChatInit without changing room id
        // this mean also: new person have to enter his name to share the same room
        next({
          name: 'chat',
          query: {
            room: to.params.room
          }
        })
      }
      next()
    }
  }
]
