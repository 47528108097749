export const initUser = ({ commit }, name) => {
  // console.log(name)
  commit('SET_INIT_USER_NAME', name)
}

export const addPeer = ({ commit }, { video, peer }) => {
  commit('SET_NEW_CLIENT', { video, peer })
}

export const setMuted = ({ commit }, value) => {
  commit('SET_MUTED', value)
}

export const setPaused = ({ commit }, value) => {
  commit('SET_PAUSED', value)
}
