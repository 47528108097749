import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import './assets/scss/style.scss'
import 'materialize-css'

import localforage from 'localforage'
localforage.config({
  driver: localforage.LOCALSTORAGE
})

window.isHome = false

export const eventBusManager = new Vue()
export const eventResetFilteredRooms = new Vue()

Vue.component('Navigation', require('./views/components/Navigation').default)

Vue.config.productionTip = false

// Vue.use(vSelect)

Vue.config.devtools = true
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
