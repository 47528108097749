<template>
  <div class="slide">
    <img :src="room.image.path" alt="">
    <div>
      <b>{{ room.price }} euro</b>
      <span :style="{marginLeft: 30 + 'px'}">{{ $tc('room.persons', room.persons) }}</span>
    </div>
    <div class="slide-bottom">
      <router-link :to="{ name: 'roomDetails', params: { id: room.id }}">{{ $t('nav.more') }} {{ room.id }}</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RoomSlide',

  data () {
    return {
      // roomLoading: false
    }
  },

  components: {
    // RoomDetails
  },
  props: {
    room: {
      type: Object
    }
  }
}
</script>

<style scoped>
  .slide {
    display: inline-block;
    overflow: hidden;
  }
  .slide-bottom{
    font-size: 0.8em;
  }

  img{
    width: 198px;
    max-height: 149px;
    margin-left: 1px;
    margin-right: 1px;
  }
  .clear-fix::after{
    clear: both;
  }
</style>
